import { useEffect } from "react";

function GroupBuyRedir() {
  useEffect(() => {
    window.location.href = "/GroupBuy";
  }, []);
  return null;
}

export default GroupBuyRedir;
