import React, { useState, useContext, useEffect, useRef } from "react";
import ScriptTag from "react-script-tag";
import style from "./styles/GroupBuyCheckout.module.css";
import "./styles/GroupBuy.css";
import "./styles/AdvertisingPage.css";
import "./styles/antStyle.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import LoadingSpinner from "../Components/Spinner/Spinner";
import Modal from "react-bootstrap/Modal";
import popupStyle from "../Components/styles/popup.module.css";
import LoginParent from "../UaaPage/login/LoginParent";
import "bootstrap/dist/css/bootstrap.min.css";
import CheckMark from "./components/CheckMark";
import PopupBanner from "../UaaPage/login/imgs/PopupBanner.png";
import gbBannerTopPhone1 from "./imgs/gb_banner_top_phone.gif";
import gbBannerTopPhone2 from "./imgs/gb_banner_top_phone_2.gif";
import gbAdd2cartPC from "./imgs/gb_add2cart_phone.webp";
import gbAdd2cartPhone from "./imgs/gb_add2cart_phone.webp";
import gbSI02PC from "./imgs/si0-2_pc.png";
import gbSI02Phone from "./imgs/si0-2_phone.png";
import gbCC02PC from "./imgs/cc0-2_pc.png";
import gbCC02Phone from "./imgs/cc0-2_phone.png";
import gbAE31PC from "./imgs/ae3-1_pc.png";
import gbAE31Phone from "./imgs/ae3-1_phone.png";
import gbSI212PC from "./imgs/si2-12_pc.png";
import gbSI212Phone from "./imgs/si2-12_phone.png";
import gbORAL212PC from "./imgs/oral2-12_pc.png";
import gbORAL212Phone from "./imgs/oral2-12_phone.png";
import orangeLine from "./imgs/orange-line.png";
import * as Scroll from "react-scroll";
import {
  Element,
  Events,
  animateScroll as scroll,
  scroller,
} from "react-scroll";

/*** GroupBuy Page ***/
import GroupBuy from "./GroupBuy";
import GroupBuyCreateGroup from "./GroupBuyCreateGroup";
import TapPay, {
  tappayConfig,
  tappaySubmit,
} from "../Components/TapPay/TapPay";

/***  antd ***/
import {
  Layout,
  Form,
  Input,
  Select,
  Steps,
  Checkbox,
  Typography,
  Collapse,
  Button as AntButton,
} from "antd";
import {
  CaretRightOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import "./styles/antStyle.css";
// import './styles/AdvertisingPage.css';

import { useCookies } from "react-cookie"; // 引入useCookies hook
import ReactGA from "react-ga4"; // GA4
import ReactPixel from "react-facebook-pixel"; // FB Pixel

import liff from "@line/liff"; // liff SDK
import AuthContext from "../UaaPage/login/components/AuthContext";

/*** UTM ***/
import UtmConfig from "../Components/Utm/UtmConfig";
import UtmEvents, {
  utmEventsInit,
  utmPageView,
  utmCheckout,
} from "../Components/Utm/UtmEvents";

function GroupBuyCheckoutAddTeam(props) {
  const {
    gbPage,
    setGbPage,
    userProductsArray,
    setUserProductsArray,
    teamId,
    setTeamId,
    orderNum,
    setOrderNum,
  } = props;
  useEffect(() => {
    // // // // // console.log(gbPage);
  }, [gbPage]);
  useEffect(() => {
    // // // // // console.log('userProductsArray',userProductsArray);
  }, [userProductsArray]);
  useEffect(() => {
    // // console.log('teamId',teamId)
  }, [teamId]);
  /***  Ant API ***/
  const { Title } = Typography; // 標題
  const { Header, Footer, Sider, Content } = Layout; // 主要內容區塊(Layout本身是組件，也可當API使用)
  const { StepItem } = Steps;
  const { Panel } = Collapse;
  const { Option } = Select;

  const kidprologinURL = process.env.REACT_APP_KIDPROLOGINURL; // KidPro 登入導向URL
  const kidprocertiURL = "https://kid-pro.com/my-account/my-certificates/"; // KidPro 完課證明頁面
  const cc02URL = "https://kid-pro.com/product/cc-0-2/";
  const si02URL = "https://kid-pro.com/product/si0-2/";
  const si212URL = "https://kid-pro.com/product/si2-12/";
  const ae31URL = "https://kid-pro.com/product/ae3-1/";

  let loginToken = window.localStorage.getItem("loginToken")
    ? window.localStorage.getItem("loginToken")
    : ""; // 取得Login Token
  let gotoKidproCerti = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + kidprocertiURL
    : kidprocertiURL; // 若Login Token存在，則KidPro LOGO導向首頁並登入；否則僅導向完課證明頁面
  let cc02 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + cc02URL
    : cc02URL;
  let si02 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + si02URL
    : si02URL;
  let si212 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + si212URL
    : si212URL;
  let ae31 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + ae31URL
    : ae31URL;

  // 測試用
  // let veriPhoneMod = phone( '+8860911087462' );
  // // // // // // console.log('veriPhoneMod',veriPhoneMod);
  // let santiPhone = veriPhoneMod.phoneNumber;
  // // // // // // console.log('santiPhone',santiPhone);

  const { setSheet } = props;
  const { authorized, setAuth, auth, setEmail, email } =
    useContext(AuthContext);
  const [NOuaabindLine, setNOuaabindLine] = useState(false); // 設定登入頁面line login 無綁定uaa
  const [Loginparent, setLoginparent] = useState(0); // 設定login parent
  const [LoginToken, setLoginToken] = useState(""); // 設定login token
  const [RegxpError, setRegxpError] = useState(""); // 顯示錯誤訊息
  // const queryString = window.location.search;
  // // // // // // // console.log('queryString',queryString);
  // const urlParams = new URLSearchParams(queryString);
  // // // // // // // console.log('urlParams.get(liff.state)',urlParams.get('liff.state'));

  const backendURL = process.env.REACT_APP_BACKENDURL; // 首頁URL
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  const microservApiURL = process.env.REACT_APP_MICROSERV_APIURL; // microserve API URL
  let LIFF_STORE = window.localStorage.getItem("LIFF_STORE");
  let LIFF_STOREregister = window.localStorage.getItem("LIFF_STOREregister");
  const lineAccessToken = !!window.localStorage.getItem("lat")
    ? window.localStorage.getItem("lat")
    : "";
  const [ShowCheckMark, setShowCheckMark] = useState(false); // 設定copy按鈕反饋checkMark
  const [loginTokenState, setloginTokenState] = useState("");

  /*** GroupBuy頁面狀態 ***/
  // const [gbPage, setGbPage] = useState("LandingPage");
  const [errMsg, setErrMsg] = useState("");
  const [errMsgs, setErrMsgs] = useState([]);
  const [
    coursePidDiscountPriceObjectArray,
    setCoursePidDiscountPriceObjectArray,
  ] = useState([{ pid: "", discount: NaN, price: NaN }]); // 設定單堂課程折扣價格
  useEffect(() => {
    // // // // // console.log('coursePidDiscountPriceObjectArray',coursePidDiscountPriceObjectArray)
  }, [coursePidDiscountPriceObjectArray]);
  const [
    coursePidDiscountPriceObjectMultiplyMembershipDiscountArray,
    setCoursePidDiscountPriceObjectMultiplyMembershipDiscountArray,
  ] = useState([{ pid: "", membership: "", discount: NaN, price: NaN }]); // 設定單堂課程折扣價格乘以會員折扣
  const [checkoutPriceInfo, setCheckoutPriceInfo] = useState({
    products: [{ pid: "", discount: NaN, amount: NaN, discountPrice: NaN }],
    subtotal: NaN,
    membership: { title: "", discount: NaN, discountPrice: NaN },
    shippingFee: NaN,
    total: NaN,
  }); // 設定結帳價格資訊
  const [checkoutProductPriceInfoArray, setCheckoutProductPriceInfoArray] =
    useState([{ pid: "", discount: NaN, amount: NaN, discountPrice: NaN }]); // 設定結帳價格資訊
  const [checkoutMembershipPriceInfo, setCheckoutMembershipPriceInfo] =
    useState({ title: "", discount: NaN, discountPrice: NaN }); // 設定結帳會員價格資訊
  const [discount, setDiscount] = useState(0); // 設定折扣
  const [price, setPrice] = useState(0); // 設定價格
  const [totalPrice, setTotalPrice] = useState(0); // 設定總計格
  const [billingState, setBillingState] = useState({
    first_name: "",
    last_name: "",
    address_1: "",
    city: "",
    state: "",
    postcode: "",
    country: "TW",
    email: "",
    phone: "",
  }); // 設定帳單資訊
  useEffect(() => {
    // // // console.log('billingState',billingState)
  }, [billingState]);
  const [shippingState, setShippingState] = useState({
    first_name: "",
    last_name: "",
    address_1: "",
    city: "",
    state: "",
    postcode: "",
    country: "TW",
    email: "",
    phone: "",
  }); // 設定寄送資訊
  useEffect(() => {
    // // // console.log('shippingState',shippingState)
  }, [shippingState]);
  const [checkoutInfoStateInit, setCheckoutInfoStateInit] = useState({}); // 設定API取得的所有結帳資訊
  const [billingStateInit, setBillingStateInit] = useState({
    first_name: "",
    last_name: "",
    address_1: "",
    city: "",
    state: "",
    postcode: "",
    country: "TW",
    email: "",
    phone: "",
  }); // 設定API取得的帳單資訊
  useEffect(() => {
    // // // console.log('billingStateInit',billingStateInit)
  }, [billingStateInit]);
  const [shippingStateInit, setShippingStateInit] = useState({
    first_name: "",
    last_name: "",
    address_1: "",
    city: "",
    state: "",
    postcode: "",
    country: "TW",
    email: "",
    phone: "",
  }); // 設定API取得的寄送資訊
  useEffect(() => {
    // // // console.log('shippingStateInit',shippingStateInit)
  }, [shippingStateInit]);
  const [dataFetched, setDataFetched] = useState(false); // 設定是否已取得訂單資料
  const [activeKey, setActiveKey] = useState([]);
  useEffect(() => {
    // // // // // console.log('activeKey',activeKey)
  }, [activeKey]);
  const [showCollapse, setShowCollapse] = useState(false);
  useEffect(() => {
    // // // // // console.log('showCollapse',showCollapse)
  }, [showCollapse]);
  const [rerender, setRerender] = useState(false);
  const [showAntLoading, setShowAntLoading] = useState(false); // 設定antd loading icon是否顯示
  const [billingFormErrMsg, setBillingFormErrMsg] = useState(""); // 帳單必填未填錯誤訊息
  const [shippingFormErrMsg, setShippingFormErrMsg] = useState(""); // 寄送表單必填未填錯誤訊息
  const [disableBtn, setDisableBtn] = useState(true); // 確認按鈕一開始不可點
  const [formFieldsInitCheckTrigger, setFormFieldsInitCheckTrigger] =
    useState(false); // Fetch表單是否已檢查
  const [billingFieldsChecked, setBillingFieldsChecked] = useState(false); // 帳單表單是否已檢查
  const [shippingFieldsChecked, setShippingFieldsChecked] = useState(false); // 運送表單是否已檢查
  const [checkShowTaxId, setCheckShowTaxId] = useState(false); // 設定是否顯示統一編號欄位
  const [checkImportBillingInfoState, setCheckImportBillingInfoState] =
    useState(false); // 設定是否匯入帳單資訊
  const [hasImportBillingInfo, setHasImportBillingInfo] = useState(false); // 設定是否已匯入帳單資訊
  const [shippingMethodState, setShippingMethodState] = useState("flat_rate"); // 設定運送方式
  const chinaCityOptions = [
    { value: "雲南", label: "雲南" },
    { value: "北京", label: "北京" },
    { value: "天津", label: "天津" },
    { value: "河北", label: "河北" },
    { value: "山西", label: "山西" },
    { value: "內蒙古", label: "內蒙古" },
    { value: "遼寧", label: "遼寧" },
    { value: "吉林", label: "吉林" },
    { value: "黑龍江", label: "黑龍江" },
    { value: "上海", label: "上海" },
    { value: "江蘇", label: "江蘇" },
    { value: "浙江", label: "浙江" },
    { value: "安徽", label: "安徽" },
    { value: "福建", label: "福建" },
    { value: "江西", label: "江西" },
    { value: "山東", label: "山東" },
    { value: "河南", label: "河南" },
    { value: "湖北", label: "湖北" },
    { value: "湖南", label: "湖南" },
    { value: "廣東", label: "廣東" },
    { value: "廣西壯族", label: "廣西壯族" },
    { value: "海南", label: "海南" },
    { value: "重慶", label: "重慶" },
    { value: "四川", label: "四川" },
    { value: "貴州", label: "貴州" },
    { value: "陝西", label: "陝西" },
    { value: "甘肅", label: "甘肅" },
    { value: "青海", label: "青海" },
    { value: "寧夏", label: "寧夏" },
    { value: "澳門", label: "澳門" },
    { value: "西藏", label: "西藏" },
    { value: "新疆", label: "新疆" },
  ];

  /*** Login Popup相關 ***/
  const [show, setShow] = useState(false); // 設定Modal 顯示與否
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Popup = (props) => {
    const { NOuaabindLine, UserData, setNOuaabindLine, LoginToken } = props;
    return (
      <>
        <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Col className={popupStyle.modal}>
              <div
                className={popupStyle.modalClose}
                onClick={(event) => {
                  handleClose();
                  clearStorage(event);
                }}
              >
                x
              </div>
              <div className={popupStyle.modalHeader}>
                <Modal.Title className={popupStyle.modalTitle}></Modal.Title>
                <div className={popupStyle.PopupBanner}>
                  <img src={PopupBanner}></img>
                </div>
              </div>
              <Modal.Body className={popupStyle.modalBody}>
                <LoginParent
                  className={popupStyle.modalLoginParent}
                  NOuaabindLine={NOuaabindLine}
                  loginTokenState={loginTokenState}
                  Loginparent={Loginparent}
                  UserData={email}
                  setNOuaabindLine={setNOuaabindLine}
                  LoginToken={LoginToken}
                ></LoginParent>
              </Modal.Body>
            </Col>
          </Modal>
        </>
      </>
    );
  }; /*** END Login Popup相關 ***/

  /*** UTM Events ***/
  const [utm, setUtm] = useState({});
  const [utmKidPro, setUtmKidPro] = useState({});
  const [utmKidPro2, setUtmKidPro2] = useState({});
  const [utmPageViewEventId, setUtmPageViewEventId] = useState("");
  const [utmAddToCartEventId, setUtmAddToCartventId] = useState("");
  const [utmCheckoutEventId, setUtmCheckoutEventId] = useState("");
  const [utmPurchaseEventId, setUtmPurchaseEventId] = useState("");
  // const Utm = UtmConfig(process.env.REACT_APP_FB_PIXEL_ID, process.env.REACT_APP_GA4_ID);
  // const UtmKidPro = UtmConfig(process.env.REACT_APP_FB_PIXEL_ID_KIDPRO, process.env.REACT_APP_GA4_ID_KIDPRO);
  const configUTM = async () => {
    try {
      // const pixelId = process.env.REACT_APP_FB_PIXEL_ID ?? "";
      const gaId = process.env.REACT_APP_GA4_ID ?? "";
      // const pixelIdKidpro = process.env.REACT_APP_FB_PIXEL_ID_KIDPRO ?? "";
      const pixelIdKidpro2 = process.env.REACT_APP_FB_PIXEL_ID_KIDPRO2 ?? "";
      const gaIdKidpro = process.env.REACT_APP_GA4_ID_KIDPRO ?? "";
      // const Utm = await UtmConfig(pixelId, gaId);
      // const UtmKidPro = await UtmConfig(pixelIdKidpro, gaIdKidpro);
      const UtmKidPro2 = await UtmConfig(pixelIdKidpro2, "");
      // setUtm(Utm);
      // setUtmKidPro(UtmKidPro);
      setUtmKidPro(UtmKidPro2);
      return {
        // Utm, UtmKidPro,
        UtmKidPro2,
      };
    } catch (error) {
      throw new Error(error);
    }
  };
  useEffect(() => {
    configUTM();
  }, []);
  const [contentName, setContentName] =
    useState("三人成團購課－結帳頁面（加團）");
  const [contentCategory, setContentCategory] = useState(
    "kidpro_marketing_groupbuy"
  );
  const [itemIds, setItemIds] = useState([]); // product id array
  const [items, setItems] = useState([]); // product object array
  const [currency, setCurrency] = useState("TWD");
  const [value, setValue] = useState(NaN);
  const [utmOrderNumber, setUtmOrderNumber] = useState(NaN);
  const [shareMethod, setShareMethod] = useState("");
  useEffect(() => {
    setContentName("三人成團購課－選課/創團頁面");
    setContentCategory("kidpro_marketing_groupbuy");
    setItemIds(userProductsArray); // product id array
    window.localStorage.setItem("pIds", JSON.stringify(userProductsArray));
    setItems(
      userProductsArray?.map((pid, index) => {
        // product object array
        const itemObj = {
          id: pid,
          quantity: 1,
          price: coursePidDiscountPriceObjectArray[index]?.price,
        };
        return itemObj;
      })
    );
    setCurrency("TWD");
    setValue(coursePidDiscountPriceObjectArray[0]?.totalPrice);
    window.localStorage.setItem(
      "tPrc",
      coursePidDiscountPriceObjectArray[0]?.totalPrice
    );
    setUtmOrderNumber(
      Array.isArray(userProductsArray) ? userProductsArray?.length : NaN
    );
  }, [userProductsArray, coursePidDiscountPriceObjectArray]);
  UtmEvents(
    contentName,
    contentCategory,
    itemIds,
    items,
    currency,
    value,
    utmOrderNumber,
    shareMethod
  ); // UTM事件全域參數設定，UtmEvents Component內已有useEffect，故不需再設定
  const utmPageViewAsync = async () => {
    const {
      // Utm, UtmKidPro,
      UtmKidPro2,
    } = await configUTM();
    // console.log({Utm, UtmKidPro})
    // await utmPageView(Utm, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    const eventID = await conversionApiViewPage();
    // await utmPageView(Utm, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    // await utmPageView(UtmKidPro, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    await utmPageView(UtmKidPro2, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
  };
  useEffect(() => {
    utmPageViewAsync();
  }, []);

  const scrollToBabyFormFunc = () => {
    // console.log(window.location.search);
  };
  useEffect(() => {
    let scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }, []);

  /*** END UTM Events ***/

  // /*** 取得UTM ***/
  // const getUtmParams = async(event) => {
  //   const urlParams = window.location.search;
  //   const urlParamsObj = new URLSearchParams(urlParams);
  //   if( urlParamsObj.has("utm_id") ) {
  //     const queryString = urlParamsObj.toString();
  //     window.localStorage.setItem("utm", queryString);
  //     const queryEventString = queryString + "&utm_event=" + event;
  //     return queryEventString;
  //   }
  //   return "";
  // }

  // /*** 第一次進入頁面傳UTM ***/
  // const sendUtmParams = async() => {
  //   const queryEventString = await getUtmParams("view");
  //   if(queryEventString) {
  //     await fetch('https://' + apiURL + '/api/marketing/v1/utm-recorder?' + queryEventString, {
  //       method: 'POST',
  //     })
  //     .then((r) => {
  //       if( ! r.ok ) {
  //         // // // // // console.log("utm sending error");
  //       }
  //     })
  //     .catch((error) => {
  //       // // // // // console.log("utm sending error", error);
  //     });
  //   }
  // }

  // const scrollToBabyFormFunc = () => {
  //   // // // // // // console.log(window.location.search);
  //   if(window.location.search.indexOf('baby-form-div')>-1) {
  //     // scroller.scrollTo('scrollToBabyForm');
  //     let scroll = Scroll.animateScroll;
  //     scroll.scrollToBottom();
  //   }
  // }

  // /*** FB Pixel 相關 ***/
  // const FbPixelID = process.env.REACT_APP_FB_PIXEL_ID;
  // // Pixel組態設定
  // const options = {
  //   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  //   debug: false, // enable logs
  // };
  // ReactPixel.init(FbPixelID, {}, options);
  // const pixelPageView = () => {
  //   ReactPixel.trackCustom('瀏覽【三人成團購課－結帳頁面】', {content_category: 'kidpro_marketing_page', content_ids: 'view_grant_membership', content_name: '三人成團購課－結帳頁面' });
  // }
  // // const pixelSubmitForm = () => {
  // //   ReactPixel.trackCustom('點選【確認更新】送出寶寶表單', {content_category: 'kidpro_marketing_submit', content_ids: 'submit_babyform', content_name: '完成LINE綁定送銀卡會員－活動頁－確認更新'});
  // // }
  // /*** END FB Pixel 相關 ***/

  // const [cookies, setCookie] = useCookies(['utm']); // useCookies hook
  // // // // // // // console.log('cookies.cid', cookies.cid);

  // /*** GA4相關 ***/
  //  // ReactGA.send({ hitType: "pageview", page: '/' }); // GA pageview設定
  // /*  page_view event parameters
  //  * page_location	string	No	https://example.com	The URL of the page.
  //  * client_id	string	No	ABC_123	The client ID.
  //  * language	string	No	en_us	The language for the client. See Language tags and codes.
  //  * page_encoding	string	No	UTF-8	The encoding of the page.
  //  * page_title	string	No	Home	The title of the page.
  //  * user_agent	string	No		The client's user agent.
  //  */
  // /* 瀏覽頁面時，送出事件給GA4 */
  // const sendGa4 = () => {
  //   ReactGA.event( 'page_view', {
  //     page_location: window.location.pathname,
  //     client_id: cookies._ga,
  //     language: 'zh_tw',
  //     page_encoding: 'UTF-8',
  //     page_title: 'Marketing - 三人成團購課－結帳頁面',
  //   });
  //   ReactGA.send({ hitType: "event" });
  //   // // // // // // console.log('ReactGA',ReactGA);
  // }
  // /*** END GA4相關 ***/

  /*** Conversion API ***/
  const conversionApiViewPage = async () => {
    let BearerToken = undefined;
    if (loginToken) {
      BearerToken = "Bearer " + loginToken;
    }
    try {
      const response = await fetch(
        "https://" +
          microservApiURL +
          "/view-content?sourceUrl=" +
          encodeURI(window.location.href),
        {
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: BearerToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Request failed: conversionApiViewPage()");
      }
      const result = await response.json();
      // console.log(result)
      if (result.success) {
        const eventID = result?.trackerData?.eventId;
        return eventID;
      } else {
        throw new Error("Request failed: conversionApiViewPage()");
        // return '';
      }
    } catch (error) {
      console.log("ConversionApiViewPage Error");
      // throw new Error('ConversionApiViewPage Error: ', error);
      // return '';
    }
  };
  // const conversionApiViewPage = async() => {
  //   let BearerToken = undefined;
  //   if( loginToken ) {
  //     BearerToken = 'Bearer ' + loginToken;
  //   }
  //   await fetch('https://' + microservApiURL + '/view-content?sourceUrl=' + encodeURI(window.location.href), {
  //     method: 'GET',
  //     credentials: "include",
  //     headers: {
  //       'Authorization': BearerToken,
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   .then((r) => r.json())
  //   .then((result) => {
  //     if(result.success) {
  //       console.log('ConversionApiViewPage Success.');
  //       return result?.trackerData?.eventId
  //     } else {
  //       console.log('ConversionApiViewPage Failure.', result);
  //       return ''
  //     }
  //   })
  //   .catch((error) => {
  //     console.log('ConversionApiViewPage Error');
  //     return ''
  //   });
  // }
  // useEffect(()=>{
  //   conversionApiViewPage();
  // },[]);
  /*** END Conversion API ***/

  /* 返回上一頁清除使用者資料 */
  const previousPageClearStorage = () => {
    // window.addEventListener("popstate", function (e) {
    window.localStorage.removeItem("username");
    window.localStorage.removeItem("smsVeriStatus");
    window.localStorage.removeItem("phoneNumber");
    window.localStorage.removeItem("url");
    // }, false);
  };

  /* 案X清除Local Storage並登出LINE、FB */
  const clearStorage = (event) => {
    event.preventDefault();
    // 如果LINE有登入，執行LINE登出
    const isLoggedIn = liff.isLoggedIn();
    if (isLoggedIn) {
      liff.logout();
    }
    // 如果FB物件存在，且為登入中，將FB登出
    if (!!window.FB) {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          window.FB.logout(function (response) {
            // // // // // // // console.log('handleFBLogout', response);
          });
        }
      });
    }
    localStorage.clear();
    setNOuaabindLine(false);
    setEmail(null);
    setSheet("LoginIndex");
  };

  //設定loading 轉圈圈
  const [isLoading, setIsLoading] = useState(false);

  /* 檢查使用者是否參加過活動或已登記活動表單(由官網登入返回後再做一次檢查並導向) */
  const checkActivityAvailable = () => {
    const loginToken = window.localStorage.getItem("loginToken")
      ? window.localStorage.getItem("loginToken")
      : "";
    const BearerToken = "Bearer " + loginToken;
    // handleShow();
    setIsLoading(true);
    // POST送出手機資料，驗證是否成功
    fetch("https://" + apiURL + "/api/marketing/event_user/share_yoga", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: BearerToken,
      },
    })
      .then((r) => r.json())
      .then((result) => {
        if (result.status === 200 && !!result.data) {
          setIsLoading(false);
          handleClose();
          alert("您已經登記過此活動囉！");
          window.location.assign(
            window.location.protocol +
              "//" +
              window.location.host +
              "/CountingPage"
          );
        } else if (result.status === 400 && result.errorCode === 1001) {
          setIsLoading(false);
          handleClose();
          alert("您已經參加過上次活動囉！");
          window.location.assign(
            window.location.protocol +
              "//" +
              window.location.host +
              "/Forbidden"
          );
        } else if (result.status === 404 && result.errorCode === 1002) {
          setIsLoading(false);
          handleClose();
          window.location.assign(
            window.location.protocol +
              "//" +
              window.location.host +
              "/SubmitPage"
          );
        } else if (result.status === 403 && result.errorCode === 1003) {
          setIsLoading(false);
          handleClose();
          alert("您還沒有取得完課證明喔！");
        } else {
          setIsLoading(false);
          alert("伺服器錯誤，請稍後再試！");
          handleClose();
          window.location.assign(
            window.location.protocol + "//" + window.location.host + "/"
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        alert("伺服器錯誤，請稍後再試！");
        handleClose();
        window.location.assign(
          window.location.protocol + "//" + window.location.host + "/"
        );
      }); // END Fetch
  };

  /* 檢查登入狀態，有JWT直接跳到填寫表單頁面；無JWT則彈出登入視窗 */
  function checkJWT() {
    // // // // // // // console.log(loginToken);
    if (!!loginToken) {
      checkActivityAvailable();
      // window.location.assign( "/CheckActivity?redir=kidpro" ); // 檢查活動並導向相應頁面
      // Hide loading screen
    } else {
      // window.location.reload();
      // 請先完成登入，才能前往登記活動！
      handleShow();
      // window.location.assign('/#');
    }
  }

  const copyText = () => {
    let copy =
      "#KidPro醫學級兒童發展教育團隊_恬兒職能治療師\n#輸入底下優惠碼享購課94折\n【#KidPro科學育兒在你身邊】";
    // let copy = '@恬兒職能治療師-醫學領域的兒童發展專家\n#KidPro優惠碼【科學育兒】購買線上課程享94折';
    navigator.clipboard
      .writeText(copy)
      .then(() => {
        //複製完成顯示checkMark
        setShowCheckMark(true);
        //複製完成三秒後checkMark消失
        setTimeout(() => {
          setShowCheckMark(false);
        }, 3000);
      })
      .catch(() => {
        setShowCheckMark(false);
      });
  };

  const lineid = process.env.REACT_APP_LINE_APP_ID;
  // // // // // // // console.log('lineid',lineid);
  let line_liff_loginData = {};

  /* 點LINE註冊，檢驗使用者是否已綁定過LINE。 (LINE登入走LoginIndex->LoginEncode->CheckActivity->導向KidPro官網登入－> ( 1.有JWT: 回CheckActivity檢驗活動狀態－>導向首頁/submitpage/countingpage ) ( 2.無JWT： 導向首頁繼續下方判斷 ) */
  const linelogin = async () => {
    const init = await liff
      .init({
        liffId: lineid, // Use own liffId
        // withLoginOnExternalBrowser: true, // Enable automatic login process
      })
      .catch((err) => {
        // Todo: retry init.
        // // // // // // // console.log('err',err);
      });
    const isLoggedIn = liff.isLoggedIn();
    // // // // // // // console.log('line',isLoggedIn);
    if (isLoggedIn === true) {
      let redirectUrl = window.location.href;
      const accessToken = liff.getAccessToken();
      line_liff_loginData = {
        accessToken: accessToken,
      };
      fetch("https://uaa.kid-pro.com/api/uaa/line_liff_login", {
        method: "POST",
        body: JSON.stringify(line_liff_loginData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((r) => r.json())
        .then((result) => {
          // // // // // // console.log('result',result);
          if (result.status === 200 && !LIFF_STOREregister) {
            // 1. LINE登入/其他狀況，使用者已綁定，導向CheckActivity進行登入並檢查活動
            // 從line登入頁面進來
            // navbar show 登出 有token  NOuaabindLine false(有綁定line and uaa)
            window.localStorage.setItem("loginToken", result.data.loginToken);
            setNOuaabindLine(false);
            setAuth({ ...auth, authorized: true });
            if (!!loginToken) {
              // window.location.assign( "/CheckActivity?redir=kidpro" );
            }
            // // // // // // console.log('01');
          } else if (result.status === 200 && LIFF_STOREregister) {
            // 2. LINE註冊，使用者已綁定
            // onsole.log('02');
            // 從註冊頁面line登入進來，有帳號uaa+line，進入帳號已存在popup
            window.localStorage.setItem(
              "lineRegisterLoginToken",
              result.data.loginToken
            ); // 只記錄login token，而不做登入；待使用者點選登入才登入
            const BearerToken = "Bearer " + result.data.loginToken;
            fetch("https://uaa.kid-pro.com/api/uaa/user_pofile", {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: BearerToken,
              },
            })
              .then((r) => r.json())
              .then((result) => {
                if (!window.localStorage.getItem("loginToken")) {
                  // setSheet('accountExist');
                  setEmail(result.data.email);
                  handleShow(); // 未登入則顯示帳號已存在，問使用者是否登入
                } else {
                  handleClose(); // 已登入則不彈出登入視窗
                }
              })
              .catch((error) => {
                // // // // // // // console.log('error', error);
                alert("伺服器錯誤，請稍候再試！");
              });
          } else if (result.statusCode === 400 && LIFF_STOREregister) {
            // 3. LINE註冊，使用者未綁定且無帳號
            //從註冊頁面line登入進來，無帳號，進入註冊
            // // // // // // // console.log('03');
            handleShow();
            // setSheet('registerPhone');
            setSheet("NOuaabindLine");
          } else if (result.statusCode === 400 && !LIFF_STOREregister) {
            // 4. LINE登入，使用者未綁定
            //從line登入頁面進來 無綁定 進入NoLineDataLogin popup
            handleShow();
            //setNOuaabindLine(false);
            setSheet("NOuaabindLine");
            // // // // // // // console.log('04');
            //show 登入
          } else if (result.statusCode === 403 && LIFF_STOREregister) {
            // 3. LINE註冊，使用者未綁定且無帳號
            //404 資料庫找不到line 資料
            //從註冊頁面line登入進來，無帳號，進入註冊
            // // // // // // // console.log('05');
            handleShow();
            setSheet("NOuaabindLine");
          } else if (result.statusCode === 403 && !LIFF_STOREregister) {
            // 4. LINE登入，使用者未綁定
            //404 資料庫找不到line 資料
            //從line登入頁面進來 無綁定 進入NoLineDataLogin popup
            handleShow(); //show 登入
            // setNOuaabindLine(true);
            setSheet("NOuaabindLine");
            // // // // // // // console.log('06');
          } else {
            alert("伺服器錯誤，請稍候再試！");
            // // // // // // // console.log('LINE登入失敗！')
            //show 登入
          }
        });
    }
  };

  /* 導向瑜珈球頁面 */
  const redirToYogaball = () => {
    const loginToken = window.localStorage.getItem("loginToken")
      ? window.localStorage.getItem("loginToken")
      : ""; // 由localStorage取得loginToken(設置登入狀態)
    const yogaballUrl = window.localStorage.getItem("yogaballUrl")
      ? window.localStorage.getItem("yogaballUrl")
      : ""; // 由localStorage取得瑜珈球頁面連結
    if (!!loginToken && !!yogaballUrl) {
      // 若以https://liff.line.me/{LineDevID}?redir=...進行登入，取得先前存入localStorage的連結，加入JWT進行官網登入導向
      const kidproLoginYogaballUrl =
        "https://kid-pro.com/wp-json/simple-jwt-login/v1/autologin?JWT=" +
        loginToken +
        "&redirectUrl=" +
        yogaballUrl;
      // // // // // // // console.log('kidproLoginYogaballUrl',kidproLoginYogaballUrl);
      // // // // // // // console.log('導向瑜珈球頁面前');
      window.localStorage.setItem("yogaballUrl", ""); // 清空導向值
      window.location.assign(kidproLoginYogaballUrl);
    }
  };

  /* 彈出註冊成功 */
  const popupRegisterSuccess = () => {
    let queryString = window.location.search; // 由驗證Email之連結取得url query字串
    let urlParams = new URLSearchParams(queryString); // 分析url query字串
    let regStatus = urlParams.get("register"); // 取得url query字串中的註冊狀態值
    // // // // // // // console.log('redir', redir);
    if (regStatus === "true") {
      // // // // // // // console.log('regStatus', regStatus );
      setSheet("registerSucess");
      handleShow();
    }
  };

  /* 看別人怎麼寫連結 */
  const seeOtherPeopleWhrite = () => {
    window.open("https://kid-pro.com/6qtv", "_blank");
  };

  const collapseToggle = (e, idx) => {
    e?.preventDefault();
    const panelI = document?.getElementsByClassName(`coursePanel-${idx}`)[0];
    if (!!panelI && panelI.style.display === "block") {
      panelI.style.display = "none";
    } else if (!!panelI) {
      panelI.style.display = "block";
    }
  };

  /*** 表單提交 ***/
  const onFinish = (values) => {
    // // // // // console.log('Form values:', values);
  };

  /*** 帳單提交 ***/
  // const submitForm = async(e) => {
  //   let submitMsg = "";
  //   // // // // // console.log(e);
  //   e?.preventDefault();
  //   if( ! loginToken ) {
  //     handleShow();
  //   } else {
  //     submitMsg = tappaySubmit(e).then((resolve, reject) => {
  //       // // // // // console.log(JSON.stringify(resolve));
  //       // setErrMsg(submitMsg);
  //     })

  //   }
  // }
  /*** 帳單提交 ***/
  const submitForm = async (e) => {
    setShowAntLoading(true);
    let submitMsg = "";
    // // // // // console.log(e);
    e?.preventDefault();
    if (!loginToken) {
      handleShow();
      setShowAntLoading(false);
    } else {
      try {
        submitMsg = await tappaySubmit(e);
        // // // // // // console.log(JSON.stringify(submitMsg));
        // Handle the resolved value here if needed
        setErrMsg(submitMsg[1]); // 0: Prime, 1: Message
        // openTeam("testPrime"); // 測試用
        if (submitMsg[0]) {
          // // // // // console.log(submitMsg[0]);
          if (!!teamId) {
            addTeam(submitMsg[0], teamId);
          } else {
            alert("無法找到您要加入的團購群組！");
            setShowAntLoading(false);
          }
        } else {
          // // // // // console.log("請檢查您的信用卡資料是否填寫正確。");
          alert("請檢查您的信用卡資料是否填寫正確。");
          setErrMsg("請檢查您的信用卡資料是否填寫正確。");
          setShowAntLoading(false);
        }
      } catch (error) {
        // // // // // console.log(error); // Handle any errors that might occur during tappaySubmit
        setShowAntLoading(false);
        // setErrMsg("An error occurred during the payment process.");
      }
    }
  };

  // /*** 開團 ***/
  // const openTeam = async (prime) => {
  //   const BearerToken = 'Bearer ' + loginToken;
  //   const data = {
  //     prime: prime,
  //     products: pidSelectedArray,
  //     billing: billingState,
  //     shipping: shippingState
  //   };
  //   // // // // // console.log('data',data);
  //   await fetch('https://' + microservApiURL + '/team', {
  //     method: 'POST',
  //     body: JSON.stringify(data),
  //     headers: {
  //       'Authorization': BearerToken,
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   .then((r) => r.json())
  //   .then((result) => {
  //     // // // // // console.log('送出TapPay Prime', result);
  //     if(result.code === 201) {
  //       alert('信用卡授權成功，您已成功建立團購！請將短網址發送給您的親朋好友，讓他們可以加入您的團購群組。');
  //       setErrMsg('信用卡授權成功，您已成功建立團購！請將短網址發送給您的親朋好友，讓他們可以加入您的團購群組。');
  //       window.open(`https://${apiURL}/GroupBuy`, '_blank');
  //       // // // // // console.log('送出TapPay Prime，成功取得回應！', result);
  //     } else {
  //       // 检查messageArray中的元素，如果包含"no"，则将其存入errMessages数组
  //       // const checkMessages = (filterMsg) => {
  //       //   const msgs = result?.message;
  //       //   const filteredMessages = msgs.filter((msg) => msg.includes(filterMsg));
  //       //   setErrMsgs(filteredMessages);
  //       // };
  //       // alert(`授權失敗，Prime錯誤！\n( Error ${result?.statusCode}：${result?.error} )\n${result?.message[0]??""}\n${result?.message[1]??""}\n${result?.message[2]??""}`);
  //       // setErrMsg(`授權失敗，Prime錯誤！\n( Error ${result?.statusCode}：${result?.error} )\n${result?.message[0]??""}\n${result?.message[1]??""}\n${result?.message[2]??""}`);
  //       // // // // // // console.log(`送出TapPay Prime，無法取得回應或資料格式錯誤！\n( Error ${result?.statusCode}：${result?.error} )\n${result?.message[0]??""}\n${result?.message[1]??""}\n${result?.message[2]??""}`);
  //       alert(`授權失敗，Prime錯誤！\n( Error ${result?.statusCode}：${result?.error} )\n${JSON.stringify(result?.message)}`);
  //       setErrMsg(`授權失敗，Prime錯誤！\n( Error ${result?.statusCode}：${result?.error} )\n${JSON.stringify(result?.message)}`);
  //       // // // // // console.log(`送出TapPay Prime，無法取得回應或資料格式錯誤！\n( Error ${result?.statusCode}：${result?.error} )\n${JSON.stringify(result?.message)}`);
  //     }
  //   })
  //   .catch((error) => {
  //     // setIsLoading(false);
  //     // // // // // console.log('error',error);
  //   });
  // }
  /*** 加入Team ***/
  const addTeam = async (prime, _id) => {
    const BearerToken = "Bearer " + loginToken;
    let billingStateTemp = billingState;
    delete billingStateTemp.getValue;
    let shippingStateTemp = shippingState;
    delete shippingStateTemp.getValue;
    delete shippingStateTemp.importBillingInfo;
    if (checkImportBillingInfoState) {
      shippingStateTemp = billingStateTemp;
    }
    const data = {
      prime: prime,
      products: userProductsArray,
      billing: billingStateTemp,
      shipping: shippingStateTemp,
      shippingMethod: shippingMethodState,
      orderNum: orderNum,
    };
    // // // // // console.log('data',data);
    await fetch("https://" + microservApiURL + "/team/join/" + _id, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Authorization: BearerToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((result) => {
        // // // // // console.log('送出TapPay Prime', result);
        if (result.code === 201) {
          const checkoutEventId = result?.trackerData?.eventId;
          // setUtmCheckoutEventId(checkoutEventId);
          sendUtmCheckout(checkoutEventId);
          // alert('信用卡授權成功，您已成功加入團購！');
          const redirectUrlFetch = result?.paymentUrl;
          if (!!redirectUrlFetch) {
            window.location.href = redirectUrlFetch;
          } else {
            alert("無法取得3D驗證位址，請稍後再試！");
          }
          // window.location.href = `/GroupBuy`;
          // // // // // console.log('送出TapPay Prime，成功取得回應！', result);
          setShowAntLoading(false);
        } else {
          // 检查messageArray中的元素，如果包含"no"，则将其存入errMessages数组
          // const checkMessages = (filterMsg) => {
          //   const msgs = result?.message;
          //   const filteredMessages = msgs.filter((msg) => msg.includes(filterMsg));
          //   setErrMsgs(filteredMessages);
          // };

          // alert(`授權失敗，Prime錯誤！\n( Error ${result?.statusCode}：${result?.error} )\n${result?.message[0]??""}\n${result?.message[1]??""}\n${result?.message[2]??""}`);
          // setErrMsg(`授權失敗，Prime錯誤！\n( Error ${result?.statusCode}：${result?.error} )\n${result?.message[0]??""}\n${result?.message[1]??""}\n${result?.message[2]??""}`);
          // // // // // // console.log(`送出TapPay Prime，無法取得回應或資料格式錯誤！\n( Error ${result?.statusCode}：${result?.error} )\n${result?.message[0]??""}\n${result?.message[1]??""}\n${result?.message[2]??""}`);
          // alert(`授權失敗，Prime錯誤！\n( Error ${result?.statusCode}：${result?.error} )\n${JSON.stringify(result?.message)}`);
          // setErrMsg(`授權失敗，Prime錯誤！\n( Error ${result?.statusCode}：${result?.error} )\n${JSON.stringify(result?.message)}`);
          alert(`授權失敗，Prime錯誤！\n${JSON.stringify(result?.message)}`);
          setErrMsg(
            `授權失敗，Prime錯誤！\n${JSON.stringify(result?.message)}`
          );
          // // // // // console.log(`送出TapPay Prime，無法取得回應或資料格式錯誤！\n( Error ${result?.statusCode}：${result?.error} )\n${JSON.stringify(result?.message)}`);
          setShowAntLoading(false);
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        // // // // // console.log('error',error);
        setShowAntLoading(false);
      });
  };
  /*** 加團送出checkout UTM***/
  const sendUtmCheckout = (utmCheckoutEventId) => {
    if (utmEventsInit && utm && utmKidPro && utmKidPro2 && utmCheckoutEventId) {
      // utmCheckout(utm, `結帳【${contentName}】`);
      // const eventID = await conversionApiViewPage();
      utmCheckout(utm, utmCheckoutEventId, `結帳【${contentName}】`);
      utmCheckout(utmKidPro, utmCheckoutEventId, `結帳【${contentName}】`);
      utmCheckout(utmKidPro2, utmCheckoutEventId, `結帳【${contentName}】`);
    }
  };
  // useEffect(() => {
  //   console.log('utmCheckoutEventId', utmCheckoutEventId)
  //   sendUtmCheckout();
  // }, [utmKidPro, utmCheckoutEventId]);

  /*** 送出TapPay Prime ***/
  // const tappayPrimeSubmit = async (prime) => {

  // }

  /**
   * 計算單堂課程折扣價格及總計
   * ids of courses
   *   33589 KidPro 嬰幼兒父母必修課
   *   20307 KidPro 嬰幼兒感覺統合啟蒙課程
   *   100358 兒童感覺統合訓練課程
   *   60464 KidPro 兒童注意力與執行力訓練課程
   *   231206 KidPro 全方位口肌訓練課程
   */
  const getCourseDiscountPrice = () => {
    const coursePriceMap = {
      20307: 5800,
      33589: 8800,
      100358: 5800,
      60464: 5800,
      231206: 8800,
    };
    const prcentageDiscount = [0.4, 0.35, 0.33, 0.32, 0.3];
    const courseLength = userProductsArray?.length;
    const discount = prcentageDiscount[courseLength - 1];
    const discountShow = Math.round(discount * 100) / 10;
    const totalPrice = userProductsArray?.reduce((total, pid) => {
      return total + Math.round(coursePriceMap[pid] * discount);
    }, 0);
    let userProductsArrayTemp = [];
    let newUserProductsArray = !!userProductsArray ? userProductsArray : [];
    userProductsArrayTemp = newUserProductsArray.map((pid) => {
      return {
        pid: pid,
        discount: discountShow,
        price: Math.round(coursePriceMap[pid] * discount),
        totalPrice: totalPrice,
      };
    });
    setCoursePidDiscountPriceObjectArray(userProductsArrayTemp);
    // return [
    //   Math.round(discount*100) / 10,
    //   Math.round(coursePriceMap[courseId]*discount),
    //   coursePriceMap[courseId]
    // ];
  };
  // 要顯示特定 pid 商品的折扣及價格的函式
  // const displayProductDiscountAndPrice = (pidToFind) => {
  //   // // // // // console.log('pidToFind',pidToFind);
  //   const product = coursePidDiscountPriceObjectArray.find(item => item.pid === pidToFind);
  //   // // // // // console.log('product',product);
  //   if(product) {
  //     const { discount, price } = product;
  //     // // // // // console.log(`找到商品 ${pidToFind}，折扣為 ${discount}，價格為 ${price}`);
  //     return [pidToFind, discount, price];
  //     // setDiscount(discount);
  //     // setPrice(price);
  //   } else {
  //     // // // // // console.log(`找不到商品 ${pidToFind}`);
  //     return [];
  //   }
  // };

  /*** 計算單堂課程折扣價格Promise ***/
  // const getCourseDiscountPricePromise = (courseId, courseLength) => {
  //   return new Promise((resolve, reject) => {
  //     const coursePriceMap = {
  //       '20307': 5800,
  //       '33589': 8800,
  //       '100358': 5800,
  //       '60464': 5800,
  //       '231206': 8800,
  //     };
  //     const prcentageDiscount = [0.4, 0.35, 0.33, 0.32, 0.3];
  //     const discount = prcentageDiscount[courseLength - 1];
  //     resolve([
  //       Math.round(discount * 100) / 10,
  //       Math.round(coursePriceMap[courseId] * discount),
  //       coursePriceMap[courseId],
  //     ]);
  //   });
  // };

  /*** 計算總計格 ***/
  // const getTotalPrice = () => {
  // // // // // // console.log(priceState);
  // let priceStateTemp = priceState;
  // userProductsArray?.forEach(async(pid) => {
  //   // // // // // console.log('totalPrice0',totalPrice);
  //   // // // // // console.log('userProductsArray?.length',userProductsArray?.length);
  //   totalPrice += await getCourseDiscountPrice(pid, userProductsArray?.length)[1];
  //   // // // // // console.log('totalPrice1',totalPrice);
  // });
  // // // // // // console.log('totalPrice2',totalPrice);
  // setTotalPrice(totalPrice);
  // priceStateTemp += priceState;
  // return priceStateTemp;
  // }

  /*** 計算總計格 ***/
  // const getTotalPrice = async () => {
  //   let totalPrice = 0;
  //   if (!userProductsArray) return; // Assuming userProductsArray is a valid array
  //   try {
  //     for (const pid of userProductsArray) {
  //       // // // // // console.log('totalPrice0', totalPrice);
  //       // // // // // console.log('userProductsArray?.length', userProductsArray.length);
  //       const coursePrice = await getCourseDiscountPricePromise(pid, userProductsArray.length)[1];
  //       // // // // // console.log('coursePrice', coursePrice);
  //       totalPrice += coursePrice;
  //       // // // // // console.log('totalPrice1', totalPrice);
  //     }
  //     // // // // // console.log('totalPrice2', totalPrice);
  //     return totalPrice;
  //   } catch (error) {
  //     console.error('Error calculating total price:', error);
  //   }
  // };
  // useEffect(() => {
  //   getTotalPrice();
  // }, [priceState]);
  // Assuming you have userProductsArray as a valid array
  // useEffect(() => {
  // 	// Function to calculate the total price and update the state
  // 	const calculateTotalPrice = async () => {
  // 		let totalPrice = 0;
  // 		try {
  // 			for( const pid of userProductsArray ) {
  // 				const {discountedPrice} = await getCourseDiscountPrice(pid, userProductsArray.length);
  // 				totalPrice += discountedPrice;
  // 			}
  // 		} catch (error) {
  // 			console.error('Error calculating total price:', error);
  // 		}
  //     setTotalPrice(totalPrice);
  // 	}
  //   calculateTotalPrice();
  // }, [userProductsArray]);

  /*** 取得帳單資訊 ***/
  // const getBillInfo = async() => {
  //   setTimeout(async() => {
  //     // // // // // console.log('getBillInfo');
  //     const BearerToken = 'Bearer ' + loginToken;
  //     await fetch('https://' + microservApiURL + '/woocommerce/customer/billing-shipping-membership', {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': BearerToken,
  //         'Accept': 'application/json'
  //       }
  //     })
  //     .then((r) => r.json())
  //     .then((result) => {
  //       // // // // // console.log('取得帳單資訊', result);
  //       const billingData = result?.billing;
  //       const shippingData = result?.shipping;
  //       // // // // // console.log('billingData',billingData);
  //       // // // // // console.log('shippingData',shippingData);
  //       setBillingState(billingData);
  //       setShippingState(shippingData);
  //       setDataFetched(true); // 设置数据已获取完成
  //     })
  //     .catch((error) => {
  //       // setIsLoading(false);
  //       // // // // // console.log('error',error);
  //     });
  //   }, 1000);
  // }
  /*** 取得帳單資訊 ***/
  const getBillInfo = async () => {
    // // // // // // // // // // console.log('getBillInfo');
    const BearerToken = "Bearer " + loginToken;
    try {
      const response = await fetch(
        "https://" +
          microservApiURL +
          "/woocommerce/customer/billing-shipping-membership",
        {
          method: "GET",
          headers: {
            Authorization: BearerToken,
            Accept: "application/json",
          },
        }
      );
      const result = await response.json();
      setCheckoutInfoStateInit(result);
      // // // console.log('取得帳單資訊', result);
      const billingData = result?.billing;
      billingData["getValue"] = true;
      const shippingData = result?.shipping;
      shippingData["getValue"] = true;
      if (billingData["email"] && !shippingData["email"]) {
        shippingData["email"] = billingData["email"];
      }
      if (!billingData["country"]) {
        billingData["country"] = "TW";
      }
      if (!shippingData["country"]) {
        shippingData["country"] = "TW";
      }
      // // // console.log('billingData', billingData);
      // // // console.log('shippingData', shippingData);
      setBillingStateInit(billingData);
      setShippingStateInit(shippingData);
      setDataFetched(true); // 设置数据已获取完成
    } catch (error) {
      // // // // // // // console.log('error', error);
    }
  };

  /*** 比較帳單資訊與寄送資訊是否相同 ***/
  function areObjectsEqual(obj1, obj2) {
    // Check if both inputs are objects
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      return false;
    }
    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }
    // Iterate through the keys and compare values
    for (const key of keys1) {
      if (!obj2.hasOwnProperty(key)) {
        return false;
      }
      const value1 = obj1[key];
      const value2 = obj2[key];
      // Recursively compare nested objects
      if (typeof value1 === "object" && typeof value2 === "object") {
        if (!areObjectsEqual(value1, value2)) {
          return false;
        }
      } else if (value1 !== value2) {
        return false;
      }
    }
    return true;
  }

  /*** 取得結帳資訊 ***/
  const getCheckoutPriceInfo = async () => {
    // const countryArray = ["AU", "CA", "FR", "DE", "IN", "ID",
    // "IT", "JP", "MY", "NZ",  "PH", "SG", "KR", "ES", "TH",
    //   "GB", "US", "VN", "CN", "TW",  "MO", "HK"];
    const sfGroup = ["CN", "MO", "HK"];
    const mappleGroup = ["TW"];
    let shippingType = "maple";
    let shippingTo = shippingState["country"];
    if (shippingTo === "None") {
      // setShippingFormErrMsg("請先填寫教具要寄送的國家/地區！");
      shippingTo = "TW";
    }
    if (sfGroup.includes(shippingTo)) {
      shippingType = "SF";
    } else if (mappleGroup.includes(shippingTo)) {
      shippingType = "maple";
    } else {
      shippingType = "post";
    }
    setShippingMethodState(shippingType);
    if (Array.isArray(userProductsArray) && userProductsArray.length !== 0) {
      const transformedArray = userProductsArray.map((item) => [
        "courseIds",
        item,
      ]);
      const courseIdParams = new URLSearchParams(transformedArray);
      const courseIdParamsString = courseIdParams.toString();
      const BearerToken = "Bearer " + loginToken;
      // // // console.log(`https://${microservApiURL}/totalfee/${shippingType}/${shippingTo}?${courseIdParamsString}`);
      try {
        const response = await fetch(
          `https://${microservApiURL}/totalfee/${shippingType}/${shippingTo}?${courseIdParamsString}`,
          {
            method: "GET",
            headers: {
              Authorization: BearerToken,
              Accept: "application/json",
            },
          }
        );
        const result = await response.json();
        setCheckoutPriceInfo(result);
        setCheckoutProductPriceInfoArray(result?.products);
        setCheckoutMembershipPriceInfo(result?.membership);
      } catch (error) {
        // // // console.log('error', error);
      }
    }
  };
  useEffect(() => {
    getCheckoutPriceInfo();
  }, [userProductsArray, shippingState]);

  /*** 取得計算會員折扣價的課程小計及課程總計 ***/
  const getMembershipDiscountPrice = () => {
    // {"billing": {...}, "shipping": {...}, "membership": { "discount":15, "title":"親子課程金卡會員" }}
    const checkoutInfoStateInitTemp = { ...checkoutInfoStateInit };
    const membershipTitle = checkoutInfoStateInitTemp?.membership?.title;
    const membershipDiscount =
      Math.round(100 - checkoutInfoStateInitTemp?.membership?.discount) / 100;
    const membershipDiscountShow =
      Math.round(100 - checkoutInfoStateInitTemp?.membership?.discount) / 10;
    // [{pid:pid, discount:discountShow, price:Math.round(coursePriceMap[pid]*discount), totalPrice:totalPrice},...]
    const coursePidDiscountPriceObjectArrayTemp = [
      ...coursePidDiscountPriceObjectArray,
    ];
    const coursePidDiscountPriceObjectMultiplyMembershipDiscountArrayTemp =
      coursePidDiscountPriceObjectArrayTemp?.map((courseObj, index) => {
        const pid = courseObj?.pid;
        const membership = membershipTitle;
        const discount = membershipDiscountShow;
        const price = Math.round(courseObj?.price * membershipDiscount);
        const totalPrice = Math.round(
          courseObj?.totalPrice * membershipDiscount
        );
        return {
          pid: pid,
          membership: membership,
          discount: discount,
          price: price,
          totalPrice: totalPrice,
        };
      });
    setCoursePidDiscountPriceObjectMultiplyMembershipDiscountArray(
      coursePidDiscountPriceObjectMultiplyMembershipDiscountArrayTemp
    );
  };
  useEffect(() => {
    getMembershipDiscountPrice();
  }, [checkoutInfoStateInit, coursePidDiscountPriceObjectArray]);

  /*** 設定是否顯示tax id checkbox狀態 ***/
  const checkShowTaxIdOnChange = (e) => {
    e?.preventDefault();
    // // // console.log('checkShowTaxId = ', e?.target?.checked);
    setCheckShowTaxId(e?.target?.checked);
  };

  /*** 載入時自動點選同帳單資訊 ***/
  const autoLoadShippingData = async () => {
    await setTimeout(async () => {
      setCheckImportBillingInfoState(true);
    }, 1000);
    await setTimeout(async () => {
      setCheckImportBillingInfoState(false);
    }, 1100);
  };
  useEffect(() => {
    autoLoadShippingData();
  }, [shippingStateInit]);

  /*** 設定是否要匯入帳單資訊checkbox狀態 ***/
  const checkImportBillingInfoOnChange = (e) => {
    e?.preventDefault();
    setCheckImportBillingInfoState(e?.target?.checked);
  };
  useEffect(() => {
    // // console.log('checkImportBillingInfoState',checkImportBillingInfoState);
  }, [checkImportBillingInfoState]);

  /*** 匯入帳單資訊 ***/
  const importBillingInfo = () => {
    // // console.log('importBillingInfo');
    const shippingData = { ...billingState };
    const shippingDataInit = { ...shippingStateInit };
    if (checkImportBillingInfoState) {
      // 點選同帳單資訊，匯入帳單資訊
      shippingData["importBillingInfo"] = true;
      setShippingState(shippingData);
      if (document.getElementById("shippingInfo")) {
        document.getElementById("shippingInfo").style.display = "none";
      }
    } else {
      // 取消點選同帳單資訊，匯入Fetch到的帳單資訊
      shippingData["importBillingInfo"] = false;
      setShippingState(shippingDataInit);
      if (document.getElementById("shippingInfo")) {
        document.getElementById("shippingInfo").style.display = "block";
      }
    }
    setHasImportBillingInfo(!hasImportBillingInfo);
  };
  useEffect(() => {
    importBillingInfo();
  }, [checkImportBillingInfoState]);

  useEffect(() => {
    autoCheckBillingFields();
    autoCheckShippingFields();
  }, [hasImportBillingInfo]);

  /*** 檢查帳單必填欄位 ***/
  const checkRequiredFieldsForBilling = (obj) => {
    // // // console.log('obj',obj)
    try {
      for (const key in obj) {
        // // // console.log(key)
        // // // console.log('obj.hasOwnProperty("first_name")',obj.hasOwnProperty("first_name"));
        // // // console.log('obj.hasOwnProperty("last_name")',obj.hasOwnProperty("last_name"));
        // // // console.log('obj.hasOwnProperty("address_1")',obj.hasOwnProperty("address_1"));
        // // // console.log('obj.hasOwnProperty("city")',obj.hasOwnProperty("city"));
        // // // console.log('obj.hasOwnProperty("state")',obj.hasOwnProperty("state"));
        // // // console.log('obj.hasOwnProperty("postcode")',obj.hasOwnProperty("postcode"));
        // // // console.log('obj.hasOwnProperty("country")',obj.hasOwnProperty("country"));
        // // // console.log('obj.hasOwnProperty("email")',obj.hasOwnProperty("email"));
        // // // console.log('obj.hasOwnProperty("phone")',obj.hasOwnProperty("phone"));
        if (
          obj.hasOwnProperty("first_name") &&
          obj.hasOwnProperty("last_name") &&
          obj.hasOwnProperty("address_1") &&
          obj.hasOwnProperty("city") &&
          obj.hasOwnProperty("state") &&
          obj.hasOwnProperty("postcode") &&
          obj.hasOwnProperty("country") &&
          obj.hasOwnProperty("email") &&
          obj.hasOwnProperty("phone")
        ) {
          if (
            key === "first_name" ||
            key === "last_name" ||
            key === "address_1" ||
            key === "city" ||
            key === "state" ||
            key === "postcode" ||
            key === "country" ||
            key === "email" ||
            key === "phone"
          ) {
            const value = obj[key];
            // // // console.log({key,value})
            if (
              value === "" ||
              value === null ||
              value === undefined ||
              value === "None"
            ) {
              // // // console.log(`${key}為必填欄位！`)
              // return `${key}為必填欄位！`;
              // // // console.log('請填寫必填欄位（*）！');
              return `請填寫必填欄位（*）！`;
            } else {
              // // // console.log('continue');
              continue;
            }
          }
        } else {
          // // // console.log(`請填寫必填欄位，並確認欄位格式無誤。`);
          return `請填寫必填欄位，並確認欄位格式無誤。`;
        }
      }
      return true;
    } catch (error) {
      // throw (new Error('checkRequiredFields() overtime!'));
      // // // console.log('請填寫必填欄位，並確認欄位格式無誤。');
      return `請填寫必填欄位，並確認欄位格式無誤。`;
    }
  };

  /*** 檢查寄送資訊必填欄位 ***/
  const checkRequiredFieldsForShipping = (obj) => {
    // console.log("checkRequiredFields")
    // console.log('obj',obj)
    try {
      for (const key in obj) {
        // // // console.log(key)
        // // // console.log('obj.hasOwnProperty("first_name")',obj.hasOwnProperty("first_name"));
        // // // console.log('obj.hasOwnProperty("last_name")',obj.hasOwnProperty("last_name"));
        // // // console.log('obj.hasOwnProperty("address_1")',obj.hasOwnProperty("address_1"));
        // // // console.log('obj.hasOwnProperty("city")',obj.hasOwnProperty("city"));
        // // // console.log('obj.hasOwnProperty("state")',obj.hasOwnProperty("state"));
        // // // console.log('obj.hasOwnProperty("postcode")',obj.hasOwnProperty("postcode"));
        // // // console.log('obj.hasOwnProperty("country")',obj.hasOwnProperty("country"));
        // console.log('obj.hasOwnProperty("email")',obj.hasOwnProperty("email"));
        // // // console.log('obj.hasOwnProperty("phone")',obj.hasOwnProperty("phone"));
        if (
          obj.hasOwnProperty("first_name") &&
          obj.hasOwnProperty("last_name") &&
          obj.hasOwnProperty("address_1") &&
          obj.hasOwnProperty("city") &&
          obj.hasOwnProperty("state") &&
          obj.hasOwnProperty("postcode") &&
          obj.hasOwnProperty("country") &&
          // obj.hasOwnProperty("email") &&
          obj.hasOwnProperty("phone")
        ) {
          if (
            key === "first_name" ||
            key === "last_name" ||
            key === "address_1" ||
            key === "city" ||
            key === "state" ||
            key === "postcode" ||
            key === "country" ||
            // key === "email" ||
            key === "phone"
          ) {
            const value = obj[key];
            // console.log({key,value})
            if (
              value === "" ||
              value === null ||
              value === undefined ||
              value === "None"
            ) {
              // console.log(`${key}為必填欄位！`)
              // return `${key}為必填欄位！`;
              // // // // // // // // // console.log('請填寫必填欄位（*）！');
              return `請填寫必填欄位（*）！`;
            } else {
              // console.log('continue');
              continue;
            }
          }
        } else {
          // console.log(`請填寫必填欄位，並確認欄位格式無誤。`);
          return `請填寫必填欄位，並確認欄位格式無誤。`;
        }
      }
      return true;
    } catch (error) {
      // throw new Error('checkRequiredFields() overtime!');
      // console.log('請填寫必填欄位，並確認欄位格式無誤。');
      return `請填寫必填欄位，並確認欄位格式無誤。`;
    }
  };

  /*** 立即檢查必填欄位 ***/
  const checkRequiredFieldsOnChange = (e, obj, str) => {
    // // // console.log({e,obj,str});
    const countryArray = [
      "AU",
      "CA",
      "FR",
      "DE",
      "IN",
      "ID",
      "IT",
      "JP",
      "MY",
      "NZ",
      "PH",
      "SG",
      "KR",
      "ES",
      "TH",
      "GB",
      "US",
      "VN",
      "CN",
      "TW",
      "MO",
      "HK",
    ];
    const pattern2 = /^[A-Za-z]{2}$/; // 2個英文字母
    const pattern4 = /^[A-Za-z]{4}$/; // 4個英文字母
    let id = "";
    let value = "";
    if (typeof e === "object") {
      e?.preventDefault();
      id = e?.target?.id;
      value = e?.target?.value;
    } else {
      if (countryArray?.includes(e)) {
        value = e;
      } else {
        value = "None";
      }
    }
    try {
      if (
        id?.toLowerCase()?.indexOf("firstname") > -1 ||
        id?.toLowerCase()?.indexOf("lastname") > -1 ||
        id?.toLowerCase()?.indexOf("address1") > -1 ||
        id?.toLowerCase()?.indexOf("city") > -1 ||
        id?.toLowerCase()?.indexOf("state") > -1 ||
        id?.toLowerCase()?.indexOf("postcode") > -1 ||
        pattern2.test(value) ||
        pattern4.test(value) ||
        (id?.toLowerCase()?.indexOf("email") > -1 &&
          id?.toLowerCase()?.indexOf("billing") > -1) ||
        id?.toLowerCase()?.indexOf("phone") > -1
      ) {
        if (
          value === "" ||
          value === null ||
          value === undefined ||
          value === "None"
        ) {
          return `請填寫必填欄位（*）！`;
        } else {
          if (
            str === "billing" &&
            checkRequiredFieldsForBilling(obj) === true
          ) {
            return true;
          } else if (
            str === "shipping" &&
            checkRequiredFieldsForShipping(obj) === true
          ) {
            return true;
          } else {
            return `請填寫必填欄位，並確認欄位格式無誤。`;
          }
        }
      }
    } catch (error) {
      // throw (new Error('checkRequiredFields() overtime!'));
      return `請填寫必填欄位，並確認欄位格式無誤。`;
    }
  };

  /*** 檢查Fetch帳單欄位 ***/
  const checkBillingFieldsInit = () => {
    // console.log("checkBillingFieldsInit")
    // console.log(checkRequiredFieldsForBilling(billingStateInit));
    if (checkRequiredFieldsForBilling(billingStateInit) !== true) {
      // 檢查未通過
      // console.log("檢查未通過");
      // setBillingFormErrMsg(checkRequiredFieldsForBilling(billingStateInit)); // 顯示錯誤訊息
      setBillingFieldsChecked(false);
    } else {
      // 檢查有通過
      // console.log("檢查通過");
      // setBillingFormErrMsg("");
      setBillingFieldsChecked(true);
    }
    setFormFieldsInitCheckTrigger(!formFieldsInitCheckTrigger);
  };
  /*** 檢查Fetch寄送欄位 ***/
  const checkShippingFieldsInit = () => {
    // console.log("checkShippingFieldsInit")
    // console.log(checkRequiredFieldsForShipping(shippingStateInit));
    if (checkRequiredFieldsForShipping(shippingStateInit) !== true) {
      // console.log("檢查未通過");
      // setShippingFormErrMsg(checkRequiredFieldsForShipping(shippingStateInit));
      setShippingFieldsChecked(false);
    } else {
      // console.log("檢查通過");
      // setShippingFormErrMsg("");
      setShippingFieldsChecked(true);
    }
    setFormFieldsInitCheckTrigger(!formFieldsInitCheckTrigger);
  };

  /*** 自動檢查帳單欄位 ***/
  const autoCheckBillingFields = () => {
    // console.log("autoCheckBillingFields")
    // console.log(checkRequiredFieldsForBilling(billingState));
    if (checkRequiredFieldsForBilling(billingState) !== true) {
      // 檢查未通過
      // console.log("檢查未通過");
      // setBillingFormErrMsg(checkRequiredFieldsForBilling(billingState)); // 顯示錯誤訊息
      setBillingFieldsChecked(false);
    } else {
      // 檢查有通過
      // console.log("檢查通過");
      // setBillingFormErrMsg("");
      setBillingFieldsChecked(true);
    }
    // setFormFieldsInitCheckTrigger( ! formFieldsInitCheckTrigger );
  };
  /*** 自動檢查寄送欄位 ***/
  const autoCheckShippingFields = () => {
    // console.log("autoCheckShippingFields")
    if (checkRequiredFieldsForShipping(shippingState) !== true) {
      // console.log("檢查未通過");
      // setShippingFormErrMsg(checkRequiredFieldsForShipping(shippingState));
      setShippingFieldsChecked(false);
    } else {
      // console.log("檢查通過");
      // setShippingFormErrMsg("");
      setShippingFieldsChecked(true);
    }
    // setFormFieldsInitCheckTrigger( ! formFieldsInitCheckTrigger );
  };

  /*** 檢查帳單欄位 ***/
  const checkBillingFields = (e) => {
    if (!!loginToken) {
      let billingStateTemp = billingState;
      setCheckImportBillingInfoState(false); // 檢查帳單欄位時，將同帳單資訊取消點選
      if (typeof e === "object") {
        e?.preventDefault();
        const id = e?.target?.id;
        const value = e?.target?.value;
        const simplifiedId = id
          ?.replace("customerDetailsForm_gbBilling", "")
          ?.toLowerCase()
          ?.replace("_", "");
        switch (simplifiedId) {
          case "firstname":
            billingStateTemp["first_name"] = value;
            break;
          case "lastname":
            billingStateTemp["last_name"] = value;
            break;
          case "address1":
            billingStateTemp["address_1"] = value;
            break;
          case "address2":
            billingStateTemp["address_2"] = value;
            break;
          default:
            billingStateTemp[simplifiedId] = value;
            break;
        }
        // // // console.log({id,value,simplifiedId});
      } else {
        billingStateTemp["country"] = e;
      }
      if (
        checkRequiredFieldsOnChange(e, billingStateTemp, "billing") !== true
      ) {
        // 檢查未通過
        // // // // // // // // console.log("檢查未通過");
        setBillingFormErrMsg(
          checkRequiredFieldsOnChange(e, billingStateTemp, "billing")
        ); // 顯示錯誤訊息
        setBillingFieldsChecked(false);
      } else {
        // 檢查有通過
        // // // // // // // // console.log("檢查通過");
        setBillingFormErrMsg("");
        setBillingFieldsChecked(true);
      }
    } else {
      handleShow();
    }
  };

  /*** 檢查寄送欄位 ***/
  const checkShippingFields = (e) => {
    if (!!loginToken) {
      let shippingStateTemp = shippingState;
      if (typeof e === "object") {
        e?.preventDefault();
        const id = e?.target?.id;
        const value = e?.target?.value;
        const simplifiedId = id
          ?.replace("customerDetailsForm_gbShipping", "")
          ?.toLowerCase()
          ?.replace("_", "");
        switch (simplifiedId) {
          case "firstname":
            shippingStateTemp["first_name"] = value;
            break;
          case "lastname":
            shippingStateTemp["last_name"] = value;
            break;
          case "address1":
            shippingStateTemp["address_1"] = value;
            break;
          case "address2":
            shippingStateTemp["address_2"] = value;
            break;
          default:
            shippingStateTemp[simplifiedId] = value;
            break;
        }
      } else {
        shippingStateTemp["country"] = e;
      }
      if (
        checkRequiredFieldsOnChange(e, shippingStateTemp, "shipping") !== true
      ) {
        // console.log("檢查未通過");
        setShippingFormErrMsg(
          checkRequiredFieldsOnChange(e, shippingStateTemp, "shipping")
        );
        setShippingFieldsChecked(false);
      } else {
        // console.log("檢查通過");
        setShippingFormErrMsg("");
        setShippingFieldsChecked(true);
      }
    } else {
      handleShow();
    }
  };

  /*** API取得的帳單資訊後，先檢查一次欄位狀態 ***/
  useEffect(() => {
    checkBillingFieldsInit();
    checkShippingFieldsInit();
  }, [billingStateInit, shippingStateInit]);

  /*** API取得的帳單資訊都檢查完後，才將狀態存入表單狀態 ***/
  useEffect(() => {
    setBillingState(billingStateInit);
    setShippingState(shippingStateInit);
  }, [formFieldsInitCheckTrigger]);

  /*** 是否要啟用確認按鈕 ***/
  const reableConfirmBtn = () => {
    // // console.log('billingFieldsChecked',billingFieldsChecked);
    // // console.log('shippingFieldsChecked',shippingFieldsChecked);
    // // // console.log('billingFieldsChecked && shippingFieldsChecked',billingFieldsChecked && shippingFieldsChecked)
    if (billingFieldsChecked && shippingFieldsChecked) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  };
  useEffect(() => {
    reableConfirmBtn();
  }, [billingFieldsChecked, shippingFieldsChecked]);

  useEffect(() => {
    getCourseDiscountPrice();
  }, [userProductsArray]);

  /*** 清除Url Query***/
  const clearUrlQuery = () => {
    const url = window.location.href;
    const urlArray = url.split("?");
    const urlWithoutQuery = urlArray[0];
    const urlQuery = urlArray[1];
    if (urlQuery) {
      window.history.replaceState({}, document.title, urlWithoutQuery);
    }
  };

  /*** 比較兩個array是否包含相同元素 ***/
  const arraysHaveCommonElement = (array1, array2) => {
    for (const element of array1) {
      if (array2.includes(element)) {
        return false;
      }
    }
    return true;
  };

  // /*** 阻擋已購課者重複購課 ***/
  // const blockUserHasCourses = async() => {
  //   if( Array.isArray(userProductsArray) && userProductsArray?.length !== 0 ) {
  //     const BearerToken = 'Bearer ' + loginToken;
  //     await fetch('https://' + microservApiURL + '/woocommerce/have-course', {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': BearerToken,
  //         'Accept': 'application/json'
  //       }
  //     })
  //     .then((r) => r.json())
  //     .then((result) => {
  //       // // console.log('顯示當前使用者已購課資訊', result);
  //       const userHasRegisteredCourseArray = result?.courseIds;
  //       const userHasRegisteredCourseArrayString = userHasRegisteredCourseArray.map(element => element.toString());
  //       const queryString = window.location.search;
  //       const urlParams = new URLSearchParams(queryString);
  //       const teamIdParams = urlParams.get('teamId');
  //       const pIdArrayParams = urlParams.getAll('pId');
  //       if( arraysHaveCommonElement(userProductsArray, userHasRegisteredCourseArrayString) && gbPage === "CheckoutPageAddTeam" ) {
  //         alert("您選擇團購的課程有些已經購買過囉！")
  //         setGbPage("CreateGroupPage");
  //       } else if( arraysHaveCommonElement(userProductsArray, userHasRegisteredCourseArrayString) && !! teamIdParams && !! pIdArrayParams ) {
  //         alert("您選擇團購的課程有些已經購買過囉！")
  //         window.location.href = "/GroupBuy";
  //       }
  //     })
  //     .catch((error) => {
  //       // alert(`無法取得已購課資訊，請稍後再試！\n${error}`);
  //       // setInterval(() => {
  //       //   getUserRegisterCourseInfo();
  //       // }, 3000);
  //       // setIsLoading(false);
  //       // // console.log('error',error);
  //     });
  //   }
  // }

  // useEffect(() => {
  //   blockUserHasCourses();
  // }, [userProductsArray]);

  useEffect(() => {
    // scrollToBabyFormFunc();

    // sendUtmParams()
    // pixelPageView();
    // sendGa4();

    linelogin();
    // redirToYogaball();
    previousPageClearStorage();

    getBillInfo();
    clearUrlQuery();
    // // console.log('userProductsArray',userProductsArray);

    // getTotalPrice();

    // tappayUpdate();
    // tappayConfig();
  }, []);

  // useEffect(() => {
  //   setErrMsg(tappayUpdate()[1]);
  // },[tappayUpdate()]);
  // useEffect(() => {
  //   // // // // // console.log('userProductsArray?.includes(33589)',userProductsArray?.includes('33589'));
  //   // // // // // console.log('userProductsArray?.includes(20307)',userProductsArray?.includes('20307'));
  //   // // // // // console.log('userProductsArray?.includes(100358)',userProductsArray?.includes('100358'));
  //   // // // // // console.log('userProductsArray?.includes(60464)',userProductsArray?.includes('60464'));
  //   // // // // // console.log('userProductsArray?.includes(231206)',userProductsArray?.includes('231206'));
  // },[userProductsArray]);
  return (
    <>
      {/* { gbPage === "CheckoutPage" && !! loginToken ? (
      <Form
        className={style.gbForm}
        name="customerDetailsForm"
        onFinish={onFinish}
        initialValues={{
          gbBillingLastName: 'Peng',
          gbBillingFirstName: 'Silves',
          gbBillingCountry: 'TW',
        }}
      >  */}
      <Container fluid className={style.container_fluid}>
        <Row className={style.gbContainerRow}>
          <Col className={style.container_customMargin}>
            <Col className={style.gbTop}>
              <Col className={style.gbTopCol1}>
                <Title level={3} className={style.titleH3_7}>
                  信用卡付款分為<b>「授權」、「請款」</b>
                  兩步驟。就像住旅館時先做預授權，退房時才會請款。
                </Title>
                <Title level={3} className={style.titleH3_7}>
                  本活動在<b>組團時只會進行授權</b>
                  ，並不會請款。您的卡號將會安全的保存在第三方刷卡公司，只有在
                  <b>成團時才會請款</b>
                  並且正式完成訂單，完成訂單後您馬上就可以觀看課程了！
                </Title>
                <Col className={style.gbTopCol1}>
                  <img
                    className={style.gbTopCol1Img}
                    src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/392b62cd-df2c-4cb7-85cf-37b89b2ab700/public"
                  ></img>
                </Col>
                <div className={style.gbQuestionCircleIconDiv}>
                  <a
                    href="https://www.ecpay.com.tw/CascadeFAQ/CascadeFAQ_Qa?nID=3706"
                    target="_blank"
                  >
                    <QuestionCircleOutlined
                      className={style.titleH3_7}
                    ></QuestionCircleOutlined>
                  </a>
                </div>
              </Col>
              {/* <Col className={style.gbTopCol1}>
                  <Title level={3} className={style.titleH3_7}>此步驟僅同意信用卡授權，成團後才會進行請款</Title>
                </Col>
                <Col className={style.gbTopCol2}>
                  <div className={style.gbTopCol2TitleGroupDiv}><Title level={3} className={`${style.titleH3_7_2} ${style.gbTitleNoGroup}`}>沒有成團</Title><Title level={3} className={style.titleH3_7_2}>會直接退刷，不會收取任何款項</Title></div>
                  <div className={style.gbTopCol2TitleGroupDiv}><Title level={3} className={`${style.titleH3_7_2} ${style.gbTitleGroup}`}>有成團</Title><Title level={3} className={style.titleH3_7_2}>將於成團後3分鐘內向您的信用卡請款</Title></div>
                </Col> */}
            </Col>
            <Col className={style.gbMain}>
              {/* {setCoursePidDiscountPriceObjectArray?.map((item, idx) => {
                  userProductsArray?.includes('33589') ? (
                    // () => {
                    //   coursePidDiscountPriceObjectArray?.map((item) => {
                    //     // // // // // console.log('item',item);
                    //     item?.pid === 33589 ? (
                    <Row className={style.gbRowCourseImg}>
                      <Col className={style.gbColCourseImg}>
                        <img className={style.gbImgCoursePC} src={gbCC02PC}></img>
                        <img className={style.gbImgCoursePhone} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/e04e3edd-8640-4b84-e36a-590fc7a6c600/public"></img>
                        <div className={style.gbColCourseDescription}>
                          <Title level={4} className={style.titleH4}>嬰幼兒 父母必修課</Title>
                          <Title level={4} className={style.titleH4_2}>原價：8800元</Title>
                          <Title level={4} className={style.titleH3_2}>團購價：{}折 {}元</Title>  
                        </div>
                      </Col>
                    </Row>
                    //     ) :(<></>)  
                    //   })
                    // }
                  ) : (<></>)
                })} */}
              {userProductsArray?.includes("33589") ? (
                <Row className={style.gbRowCourseImg}>
                  <Col className={style.gbColCourseImg}>
                    {/* <img className={style.gbImgCoursePC} src={"https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/daff0a38-9410-476b-2191-a49ff5219400/public"}></img> */}
                    {/* <img className={`${style.gbImgCoursePhone} ${style.gbImgCoursePhoneCC02}`} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/e04e3edd-8640-4b84-e36a-590fc7a6c600/public" onClick={() => { 
                          window.open("https://kid-pro.com/product/cc-0-2/") }}></img> */}
                    <img
                      className={`${style.gbImgCoursePhone}`}
                      src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/c201c113-e121-4b28-15fa-1b08f9a8b500/keepOrigin"
                      onClick={() => {
                        window.open("https://kid-pro.com/product/cc-0-2/");
                      }}
                    ></img>
                    <div className={style.gbColCourseDescription}>
                      <Title level={4} className={style.titleH4}>
                        嬰幼兒 父母必修課
                      </Title>
                      <Title level={4} className={style.titleH4_2}>
                        原價：8800元
                      </Title>
                      {coursePidDiscountPriceObjectArray
                        ?.filter((item) => item.pid === "33589")
                        ?.map((item, idx) => {
                          return (
                            <Title level={4} className={style.titleH3_2}>
                              團購價：{item?.discount}折 {item?.price}元
                            </Title>
                          );
                        })}
                    </div>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {userProductsArray?.includes("20307") ? (
                <Row className={style.gbRowCourseImg}>
                  <Col className={style.gbColCourseImg}>
                    {/* <img className={style.gbImgCoursePC} src={"https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8e3dd06c-914c-4f04-c7e9-1c93a15c6e00/public"}></img> */}
                    <img
                      className={style.gbImgCoursePhone}
                      src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/28e0831b-6f6c-41ba-c385-b30c2719ec00/keepOrigin"
                      onClick={() => {
                        window.open("https://kid-pro.com/product/si0-2/");
                      }}
                    ></img>
                    <div className={style.gbColCourseDescription}>
                      <Title level={4} className={style.titleH4}>
                        嬰幼兒 感覺統合啟蒙課程
                      </Title>
                      <Title level={4} className={style.titleH4_2}>
                        原價：5800元
                      </Title>
                      {coursePidDiscountPriceObjectArray
                        ?.filter((item) => item.pid === "20307")
                        ?.map((item, idx) => {
                          return (
                            <Title level={4} className={style.titleH3_2}>
                              團購價：{item?.discount}折 {item?.price}元
                            </Title>
                          );
                        })}
                    </div>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {userProductsArray?.includes("100358") ? (
                <Row className={style.gbRowCourseImg}>
                  <Col className={style.gbColCourseImg}>
                    {/* <img className={style.gbImgCoursePC} src={"https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/94861a9b-0d29-428f-cb6f-9150a5141200/public"}></img> */}
                    <img
                      className={style.gbImgCoursePhone}
                      src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/12da8e21-c7b6-4c4d-7b74-704fd7ed6800/keepOrigin"
                      onClick={() => {
                        window.open("https://kid-pro.com/product/si-2-12/");
                      }}
                    ></img>
                    <div className={style.gbColCourseDescription}>
                      <Title level={4} className={style.titleH4}>
                        兒童 感覺統合訓練課程
                      </Title>
                      <Title level={4} className={style.titleH4_2}>
                        原價：5800元
                      </Title>
                      {coursePidDiscountPriceObjectArray
                        ?.filter((item) => item.pid === "100358")
                        ?.map((item, idx) => {
                          return (
                            <Title level={4} className={style.titleH3_2}>
                              團購價：{item?.discount}折 {item?.price}元
                            </Title>
                          );
                        })}
                    </div>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {userProductsArray?.includes("60464") ? (
                <Row className={style.gbRowCourseImg}>
                  <Col className={style.gbColCourseImg}>
                    {/* <img className={style.gbImgCoursePC} src={"https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/5c836a40-7cb2-4a79-151d-ecf65baffc00/public"}></img> */}
                    <img
                      className={style.gbImgCoursePhone}
                      src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/86a6122a-e1b9-4899-f15d-1f0bba1b0b00/keepOrigin"
                      onClick={() => {
                        window.open("https://kid-pro.com/product/ae3-1/");
                      }}
                    ></img>
                    <div className={style.gbColCourseDescription}>
                      <Title level={4} className={style.titleH4}>
                        兒童 注意力與執行力訓練課程
                      </Title>
                      <Title level={4} className={style.titleH4_2}>
                        原價：5800元
                      </Title>
                      <Title
                        level={4}
                        className={`${style.titleH4_2} ${style.titleH4_9}`}
                      >
                        教具包預購中，預計02/03起依訂單順序出貨
                      </Title>
                      {coursePidDiscountPriceObjectArray
                        ?.filter((item) => item.pid === "60464")
                        ?.map((item, idx) => {
                          return (
                            <Title level={4} className={style.titleH3_2}>
                              團購價：{item?.discount}折 {item?.price}元
                            </Title>
                          );
                        })}
                    </div>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {userProductsArray?.includes("231206") ? (
                <Row className={style.gbRowCourseImg}>
                  <Col className={style.gbColCourseImg}>
                    {/* <img className={style.gbImgCoursePC} src={"https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/d699cd49-f5ed-4ad9-3490-911c40563d00/public"}></img> */}
                    <img
                      className={style.gbImgCoursePhone}
                      src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/c41c80db-adba-46d3-61e7-7e765092b500/keepOrigin"
                      onClick={() => {
                        window.open("https://kid-pro.com/product/oral-2-12/");
                      }}
                    ></img>
                    <div className={style.gbColCourseDescription}>
                      <Title level={4} className={style.titleH4}>
                        兒童/成人 玩美臉型自信開口
                      </Title>
                      <Title level={4} className={style.titleH4}>
                        全方位口肌訓練課程
                      </Title>
                      <Title level={4} className={style.titleH4_2}>
                        原價：8800元
                      </Title>
                      <Title
                        level={4}
                        className={`${style.titleH4_2} ${style.titleH4_9}`}
                      >
                        教具包預購中，預計02/03起依訂單順序出貨
                      </Title>
                      {coursePidDiscountPriceObjectArray
                        ?.filter((item) => item.pid === "231206")
                        ?.map((item, idx) => {
                          return (
                            <Title level={4} className={style.titleH3_2}>
                              團購價：{item?.discount}折 {item?.price}元
                            </Title>
                          );
                        })}
                    </div>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {!billingStateInit?.getValue ? (
                <Content
                  className={`${style.gbRowCourseImg} ${style.gbContentToolAddress}`}
                >
                  <section
                    className={`${style.gbColCourseImg} ${style.gbContentSecToolAddress}`}
                  >
                    <Collapse
                      className={style.gbCollapseBillingForm}
                      defaultActiveKey={["billingInfo"]} // 根据 dataFetched 决定是否展开
                      ghost
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                      )}
                      expandIconPosition="end"
                      onChange={(keys) => {
                        // // // // // // console.log(keys);
                        setActiveKey(keys); // 设置当前展开的面板
                        // if (!dataFetched && keys.includes('billingInfo')) {
                        // setShowCollapse('billingInfo'); // 设置当前展开的面板
                        getBillInfo(); // 在第一次展开时调用 getBillInfo() 来获取数据
                        // }
                      }}
                    >
                      <Panel
                        className={style.gbPanelBillingForm}
                        header={
                          <Title level={3} className={style.titleH3_5Tool}>
                            帳單資訊
                          </Title>
                        }
                        key={"billingInfo"}
                      >
                        <div className={style.gbContentSecDivToolAddress}>
                          {/* <div className={style.gbUnderlineInputDiv}>
                              <input className={style.gbUnderlineInput} type="text" />
                            </div>    */}
                          <div className={style.gbContentSecDivDivToolAddress}>
                            <div className={style.gbCol1}>
                              <Form
                                className={style.gbForm}
                                name="customerDetailsForm"
                                onFinish={onFinish}
                                initialValues={{
                                  gbBillingLastName:
                                    billingStateInit?.last_name,
                                  gbBillingFirstName:
                                    billingStateInit?.first_name,
                                  gbBillingCountry: billingStateInit?.country,
                                  gbBillingPostcode: billingStateInit?.postcode,
                                  gbBillingCity: billingStateInit?.city,
                                  gbBillingState: billingStateInit?.state,
                                  gbBillingAddress1:
                                    billingStateInit?.address_1,
                                  gbBillingAddress2:
                                    billingStateInit?.address_2,
                                  gbBillingPhone: billingStateInit?.phone,
                                  gbBillingEmail: billingStateInit?.email,
                                  gbBillingCompany: billingStateInit?.company,
                                }}
                              >
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowFirst} ${style.gbValidateRequired}`}
                                  id="gbBillingLastName"
                                  name="gbBillingLastName"
                                  label="姓氏 ( Last Name )"
                                  rules={[
                                    { required: true, message: "請輸入姓氏" },
                                  ]}
                                  onChange={(e) => {
                                    // if( billingState?.last_name ) {
                                    //   setBillingState({ ...billingState, last_name: billingState?.last_name });
                                    // }
                                    setBillingState({
                                      ...billingState,
                                      last_name: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={`${style.gbInputText} ${style.gbInputTextLastName}`}
                                  />
                                  {/* <input className={`${style.gbInputText} ${style.gbInputTextLastName}`} value={billingState?.last_name}></input> */}
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowLast} ${style.gbValidateRequired}`}
                                  id="gbBillingFirstName"
                                  name="gbBillingFirstName"
                                  label="名字 ( First Name )"
                                  rules={[
                                    { required: true, message: "請輸入名字" },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      first_name: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={`${style.gbInputText} ${style.gbInputTextFirstName}`}
                                  />
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowCheckbox}`}
                                  id="gbBillingCheckNeedTaxID"
                                  name="gbBillingCheckNeedTaxID"
                                >
                                  <Checkbox
                                    checked={checkShowTaxId}
                                    onChange={(e) => {
                                      checkShowTaxIdOnChange(e);
                                    }}
                                  >
                                    是否要打統編？
                                  </Checkbox>
                                </Form.Item>
                                {checkShowTaxId ? (
                                  <>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowWide}`}
                                      id="gbBillingCompany"
                                      name="gbBillingCompany"
                                      label="公司名稱 ( Company )（選填）"
                                      onChange={(e) =>
                                        setBillingState({
                                          ...billingState,
                                          company: e.target.value,
                                        })
                                      }
                                    >
                                      <Input className={style.gbInputText} />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowWide}`}
                                      id="gbBillingTaxId"
                                      name="gbBillingTaxId"
                                      label="統一編號 ( Tax ID Number )（選填）"
                                      onChange={(e) =>
                                        setBillingState({
                                          ...billingState,
                                          taxIdNumber: e.target.value,
                                        })
                                      }
                                    >
                                      <Input className={style.gbInputText} />
                                    </Form.Item>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbUpdateTotalsOnChange} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                  id="gbBillingCountry"
                                  name="gbBillingCountry"
                                  label="國家/地區或區域 ( Country / Region )"
                                  rules={[
                                    {
                                      required: true,
                                      message: "請選擇國家/地區或區域",
                                    },
                                  ]}
                                >
                                  <Select
                                    aria-hidden="true"
                                    className={style.gbCountryToState}
                                    placeholder="選擇國家/地區或區域…"
                                    onChange={(value) => {
                                      setBillingState({
                                        ...billingState,
                                        country: value,
                                      });
                                      checkBillingFields(value);
                                    }}
                                  >
                                    <Select.Option value="None">
                                      選擇國家/地區或區域…
                                    </Select.Option>
                                    <Select.Option value="AU">
                                      Australia
                                    </Select.Option>
                                    <Select.Option value="CA">
                                      Canada
                                    </Select.Option>
                                    <Select.Option value="FR">
                                      France
                                    </Select.Option>
                                    <Select.Option value="DE">
                                      Germany
                                    </Select.Option>
                                    <Select.Option value="IN">
                                      India
                                    </Select.Option>
                                    <Select.Option value="ID">
                                      Indonesia
                                    </Select.Option>
                                    <Select.Option value="IT">
                                      Italy
                                    </Select.Option>
                                    <Select.Option value="JP">
                                      Japan
                                    </Select.Option>
                                    <Select.Option value="MY">
                                      Malaysia
                                    </Select.Option>
                                    <Select.Option value="NZ">
                                      New Zealand
                                    </Select.Option>
                                    <Select.Option value="PH">
                                      Philippines
                                    </Select.Option>
                                    <Select.Option value="SG">
                                      Singapore
                                    </Select.Option>
                                    <Select.Option value="KR">
                                      South Korea
                                    </Select.Option>
                                    <Select.Option value="ES">
                                      Spain
                                    </Select.Option>
                                    <Select.Option value="TH">
                                      Thailand
                                    </Select.Option>
                                    <Select.Option value="GB">
                                      United Kingdom (UK)
                                    </Select.Option>
                                    <Select.Option value="US">
                                      United States (US)
                                    </Select.Option>
                                    <Select.Option value="VN">
                                      Vietnam
                                    </Select.Option>
                                    <Select.Option value="CN">
                                      中国
                                    </Select.Option>
                                    <Select.Option
                                      selected="selected"
                                      value="TW"
                                    >
                                      台灣
                                    </Select.Option>
                                    <Select.Option value="MO">
                                      澳門
                                    </Select.Option>
                                    <Select.Option value="HK">
                                      香港
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowPostcode} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                  id="gbBillingPostcode"
                                  name="gbBillingPostcode"
                                  label="郵遞區號 ( Zip Code )"
                                  rules={[
                                    {
                                      required: true,
                                      message: "請輸入郵遞區號",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      postcode: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={`${style.gbInputText} ${style.gbInputTextPostcode}`}
                                  />
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidateState}`}
                                  id="gbBillingState"
                                  name="gbBillingState"
                                  label="城市 ( State, City, Province )"
                                  rules={[
                                    { required: true, message: "請輸入城市" },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      state: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={`${style.gbInputText} ${style.gbInputTextCity}`}
                                    placeholder="台北市、台南市、或基隆市等..."
                                  />
                                </Form.Item>
                                {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbUpdateTotalsOnChange} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                    id="gbBillingState"
                                    name="gbBillingState"
                                    label="省份/城市 ( State, City, Province )"
                                    rules={[{ required: true, message: '請選擇省份/城市' }]}
                                    onChange={(e)=>{ setBillingState({ ...billingState, state: e.target.value }); checkBillingFields(e); }}
                                  >
                                    <Select
                                      aria-hidden="true"
                                      className={style.gbCountryToState}
                                      placeholder="選擇省份/城市..."
                                    >
                                      {chinaCityOptions.map((option) => (
                                        <Select.Option key={option.value} value={option.value}>
                                          {option.label}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item> */}
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                  id="gbBillingCity"
                                  name="gbBillingCity"
                                  label="鄉鎮市區 ( District, Area, County )"
                                  rules={[
                                    {
                                      required: true,
                                      message: "請輸入鄉鎮市區",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      city: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={style.gbInputText}
                                    placeholder="如：尖石鄉、竹南鎮、員林市、中正區等..."
                                  />
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                  id="gbBillingAddress1"
                                  name="gbBillingAddress1"
                                  label="街道地址 ( Street Address )"
                                  rules={[
                                    {
                                      required: true,
                                      message: "請輸入街道地址",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      address_1: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={`${style.gbInputText} ${style.gbInputTextAddress}`}
                                    placeholder="門牌號碼與街道名稱"
                                  />
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide}`}
                                  id="gbBillingAddress2"
                                  name="gbBillingAddress2"
                                  label="地址第二行 ( 2nd Line Street Address )（選填）"
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      address_2: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={`${style.gbInputText} ${style.gbInputTextAddress}`}
                                    placeholder="公寓、套房、單元等 ( 選填 )"
                                  />
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidatePhone}`}
                                  id="gbBillingPhone"
                                  name="gbBillingPhone"
                                  label="聯絡電話 ( Phone )"
                                  rules={[
                                    {
                                      required: true,
                                      message: "請輸入聯絡電話",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      phone: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={style.gbInputText}
                                    placeholder="0912345678 或 02-12345678"
                                  />
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidateEmail}`}
                                  id="gbBillingEmail"
                                  name="gbBillingEmail"
                                  label="電子郵件 ( E-mail )"
                                  rules={[
                                    {
                                      required: true,
                                      message: "請輸入電子郵件",
                                    },
                                    {
                                      type: "email",
                                      message: "請輸入有效的電子郵件地址",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      email: e.target.value,
                                    });
                                    setShippingState({
                                      ...shippingState,
                                      email: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={style.gbInputText}
                                    placeholder="如：username@domain.com"
                                  />
                                </Form.Item>
                                <Row className={style.gbBottomRow3}>
                                  <div className={style.gbErrMsg}>
                                    {billingFormErrMsg}
                                  </div>
                                </Row>
                                {/* 其他表單元素省略 */}
                                {/* <Form.Item>
                                    <button className="ant-btn" type="submit">
                                      更新國家/地區或區域
                                    </button>
                                  </Form.Item> */}
                              </Form>
                            </div>
                            <div className={style.gbCol2}></div>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </section>
                </Content>
              ) : (
                <Content
                  className={`${style.gbRowCourseImg} ${style.gbContentToolAddress}`}
                >
                  <section
                    className={`${style.gbColCourseImg} ${style.gbContentSecToolAddress}`}
                  >
                    <div style={{ display: "none" }}>
                      {JSON.stringify(billingState)}
                    </div>
                    <Collapse
                      className={style.gbCollapseBillingForm}
                      defaultActiveKey={["billingInfo"]} // 根据 dataFetched 决定是否展开
                      ghost
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                      )}
                      expandIconPosition="end"
                      onChange={(keys) => {
                        // // // // // // console.log(keys);
                        setActiveKey(keys); // 设置当前展开的面板
                        // if (!dataFetched && keys.includes('billingInfo')) {
                        // setShowCollapse('billingInfo'); // 设置当前展开的面板
                        getBillInfo(); // 在第一次展开时调用 getBillInfo() 来获取数据
                        // }
                      }}
                    >
                      <Panel
                        className={style.gbPanelBillingForm}
                        header={
                          <Title level={3} className={style.titleH3_5Tool}>
                            帳單資訊
                          </Title>
                        }
                        key={"billingInfo"}
                      >
                        <div className={style.gbContentSecDivToolAddress}>
                          {/* <div className={style.gbUnderlineInputDiv}>
                              <input className={style.gbUnderlineInput} type="text" />
                            </div>    */}
                          <div className={style.gbContentSecDivDivToolAddress}>
                            <div className={style.gbCol1}>
                              <Form
                                className={style.gbForm}
                                name="customerDetailsForm"
                                onFinish={onFinish}
                                initialValues={{
                                  gbBillingLastName:
                                    billingStateInit?.last_name,
                                  gbBillingFirstName:
                                    billingStateInit?.first_name,
                                  gbBillingCountry: billingStateInit?.country,
                                  gbBillingPostcode: billingStateInit?.postcode,
                                  gbBillingCity: billingStateInit?.city,
                                  gbBillingState: billingStateInit?.state,
                                  gbBillingAddress1:
                                    billingStateInit?.address_1,
                                  gbBillingAddress2:
                                    billingStateInit?.address_2,
                                  gbBillingPhone: billingStateInit?.phone,
                                  gbBillingEmail: billingStateInit?.email,
                                  gbBillingCompany: billingStateInit?.company,
                                }}
                              >
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowFirst} ${style.gbValidateRequired}`}
                                  id="gbBillingLastName"
                                  name="gbBillingLastName"
                                  label="姓氏 ( Last Name )"
                                  rules={[
                                    { required: true, message: "請輸入姓氏" },
                                  ]}
                                  onChange={(e) => {
                                    // if( billingState?.last_name ) {
                                    //   setBillingState({ ...billingState, last_name: billingState?.last_name });
                                    // }
                                    setBillingState({
                                      ...billingState,
                                      last_name: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={`${style.gbInputText} ${style.gbInputTextLastName}`}
                                  />
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowLast} ${style.gbValidateRequired}`}
                                  id="gbBillingFirstName"
                                  name="gbBillingFirstName"
                                  label="名字 ( First Name )"
                                  rules={[
                                    { required: true, message: "請輸入名字" },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      first_name: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={`${style.gbInputText} ${style.gbInputTextFirstName}`}
                                  />
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowCheckbox}`}
                                  id="gbBillingCheckNeedTaxID"
                                  name="gbBillingCheckNeedTaxID"
                                >
                                  <Checkbox
                                    checked={checkShowTaxId}
                                    onChange={(e) => {
                                      checkShowTaxIdOnChange(e);
                                    }}
                                  >
                                    是否要打統編？
                                  </Checkbox>
                                </Form.Item>
                                {checkShowTaxId ? (
                                  <>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowWide}`}
                                      id="gbBillingCompany"
                                      name="gbBillingCompany"
                                      label="公司名稱 ( Company )（選填）"
                                      onChange={(e) =>
                                        setBillingState({
                                          ...billingState,
                                          company: e.target.value,
                                        })
                                      }
                                    >
                                      <Input className={style.gbInputText} />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowWide}`}
                                      id="gbBillingTaxId"
                                      name="gbBillingTaxId"
                                      label="統一編號 ( Tax ID Number )（選填）"
                                      onChange={(e) =>
                                        setBillingState({
                                          ...billingState,
                                          taxIdNumber: e.target.value,
                                        })
                                      }
                                    >
                                      <Input className={style.gbInputText} />
                                    </Form.Item>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbUpdateTotalsOnChange} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                  id="gbBillingCountry"
                                  name="gbBillingCountry"
                                  label="國家/地區或區域 ( Country / Region )"
                                  rules={[
                                    {
                                      required: true,
                                      message: "請選擇國家/地區或區域",
                                    },
                                  ]}
                                >
                                  <Select
                                    aria-hidden="true"
                                    className={style.gbCountryToState}
                                    placeholder="選擇國家/地區或區域…"
                                    onChange={(value) => {
                                      setBillingState({
                                        ...billingState,
                                        country: value,
                                      });
                                      checkBillingFields(value);
                                    }}
                                  >
                                    <Select.Option value="None">
                                      選擇國家/地區或區域…
                                    </Select.Option>
                                    <Select.Option value="AU">
                                      Australia
                                    </Select.Option>
                                    <Select.Option value="CA">
                                      Canada
                                    </Select.Option>
                                    <Select.Option value="FR">
                                      France
                                    </Select.Option>
                                    <Select.Option value="DE">
                                      Germany
                                    </Select.Option>
                                    <Select.Option value="IN">
                                      India
                                    </Select.Option>
                                    <Select.Option value="ID">
                                      Indonesia
                                    </Select.Option>
                                    <Select.Option value="IT">
                                      Italy
                                    </Select.Option>
                                    <Select.Option value="JP">
                                      Japan
                                    </Select.Option>
                                    <Select.Option value="MY">
                                      Malaysia
                                    </Select.Option>
                                    <Select.Option value="NZ">
                                      New Zealand
                                    </Select.Option>
                                    <Select.Option value="PH">
                                      Philippines
                                    </Select.Option>
                                    <Select.Option value="SG">
                                      Singapore
                                    </Select.Option>
                                    <Select.Option value="KR">
                                      South Korea
                                    </Select.Option>
                                    <Select.Option value="ES">
                                      Spain
                                    </Select.Option>
                                    <Select.Option value="TH">
                                      Thailand
                                    </Select.Option>
                                    <Select.Option value="GB">
                                      United Kingdom (UK)
                                    </Select.Option>
                                    <Select.Option value="US">
                                      United States (US)
                                    </Select.Option>
                                    <Select.Option value="VN">
                                      Vietnam
                                    </Select.Option>
                                    <Select.Option value="CN">
                                      中国
                                    </Select.Option>
                                    <Select.Option
                                      selected="selected"
                                      value="TW"
                                    >
                                      台灣
                                    </Select.Option>
                                    <Select.Option value="MO">
                                      澳門
                                    </Select.Option>
                                    <Select.Option value="HK">
                                      香港
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowPostcode} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                  id="gbBillingPostcode"
                                  name="gbBillingPostcode"
                                  label="郵遞區號 ( Zip Code )"
                                  rules={[
                                    {
                                      required: true,
                                      message: "請輸入郵遞區號",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      postcode: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={`${style.gbInputText} ${style.gbInputTextPostcode}`}
                                  />
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidateState}`}
                                  id="gbBillingState"
                                  name="gbBillingState"
                                  label="城市 ( State, City, Province )"
                                  rules={[
                                    { required: true, message: "請輸入城市" },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      state: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={`${style.gbInputText} ${style.gbInputTextCity}`}
                                    placeholder="台北市、台南市、或基隆市等..."
                                  />
                                </Form.Item>
                                {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbUpdateTotalsOnChange} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                    id="gbBillingState"
                                    name="gbBillingState"
                                    label="省份/城市 ( State, City, Province )"
                                    rules={[{ required: true, message: '請選擇省份/城市' }]}
                                    onChange={(e)=>{ setBillingState({ ...billingState, state: e.target.value }); checkBillingFields(e); }}
                                  >
                                    <Select
                                      aria-hidden="true"
                                      className={style.gbCountryToState}
                                      placeholder="選擇省份/城市..."
                                    >
                                      {chinaCityOptions.map((option) => (
                                        <Select.Option key={option.value} value={option.value}>
                                          {option.label}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item> */}
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                  id="gbBillingCity"
                                  name="gbBillingCity"
                                  label="鄉鎮市區 ( District, Area, County )"
                                  rules={[
                                    {
                                      required: true,
                                      message: "請輸入鄉鎮市區",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      city: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={style.gbInputText}
                                    placeholder="如：尖石鄉、竹南鎮、員林市、中正區等..."
                                  />
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                  id="gbBillingAddress1"
                                  name="gbBillingAddress1"
                                  label="街道地址 ( Street Address )"
                                  rules={[
                                    {
                                      required: true,
                                      message: "請輸入街道地址",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      address_1: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={`${style.gbInputText} ${style.gbInputTextAddress}`}
                                    placeholder="門牌號碼與街道名稱"
                                  />
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide}`}
                                  id="gbBillingAddress2"
                                  name="gbBillingAddress2"
                                  label="地址第二行 ( 2nd Line Street Address )（選填）"
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      address_2: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={`${style.gbInputText} ${style.gbInputTextAddress}`}
                                    placeholder="公寓、套房、單元等 ( 選填 )"
                                  />
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidatePhone}`}
                                  id="gbBillingPhone"
                                  name="gbBillingPhone"
                                  label="聯絡電話 ( Phone )"
                                  rules={[
                                    {
                                      required: true,
                                      message: "請輸入聯絡電話",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      phone: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={style.gbInputText}
                                    placeholder="0912345678 或 02-12345678"
                                  />
                                </Form.Item>
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidateEmail}`}
                                  id="gbBillingEmail"
                                  name="gbBillingEmail"
                                  label="電子郵件 ( E-mail )"
                                  rules={[
                                    {
                                      required: true,
                                      message: "請輸入電子郵件",
                                    },
                                    {
                                      type: "email",
                                      message: "請輸入有效的電子郵件地址",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    setBillingState({
                                      ...billingState,
                                      email: e.target.value,
                                    });
                                    setShippingState({
                                      ...shippingState,
                                      email: e.target.value,
                                    });
                                    checkBillingFields(e);
                                  }}
                                >
                                  <Input
                                    className={style.gbInputText}
                                    placeholder="如：username@domain.com"
                                  />
                                </Form.Item>
                                <Row className={style.gbBottomRow3}>
                                  <div className={style.gbErrMsg}>
                                    {billingFormErrMsg}
                                  </div>
                                </Row>
                                {/* 其他表單元素省略 */}
                                {/* <Form.Item>
                                    <button className="ant-btn" type="submit">
                                      更新國家/地區或區域
                                    </button>
                                  </Form.Item> */}
                              </Form>
                            </div>
                            <div className={style.gbCol2}></div>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </section>
                </Content>
              )}
              {!shippingStateInit?.getValue &&
              !shippingState?.importBillingInfo ? ( // 初始狀態： false && false
                <Content
                  className={`${style.gbRowCourseImg} ${style.gbContentToolAddress}`}
                >
                  {/* <div style={{display:"none"}}>{JSON.stringify(shippingState)}</div> */}
                  <section
                    className={`${style.gbColCourseImg} ${style.gbContentSecToolAddress}`}
                  >
                    <Collapse
                      className={style.gbCollapseBillingForm}
                      defaultActiveKey={["shippingInfo"]}
                      collapsible="disabled"
                      ghost
                      expandIcon={({ isActive }) => <></>}
                      expandIconPosition="end"
                      onChange={(keys) => {
                        setActiveKey(keys); // 设置当前展开的面板
                        // if (!dataFetched && keys.includes('shippingInfo')) {
                        // setShowCollapse('shippingInfo');
                        getBillInfo(); // 在第一次展开时调用 getBillInfo() 来获取数据
                        // }
                      }}
                    >
                      <Panel
                        className={style.gbPanelBillingForm}
                        header={
                          <Title level={3} className={style.titleH3_5Tool}>
                            寄送資訊<br></br>(
                            注意力與執行力訓練課程、口肌訓練課程 )
                          </Title>
                        }
                        key={"shippingInfo"}
                      >
                        <div className={style.gbContentSecDivToolAddress}>
                          {/* <div className={style.gbUnderlineInputDiv}>
                              <input className={style.gbUnderlineInput} type="text" />
                            </div>    */}
                          <div className={style.gbContentSecDivDivToolAddress}>
                            <div className={style.gbCol1}>
                              <Form
                                className={style.gbForm}
                                name="customerDetailsForm"
                                onFinish={onFinish}
                                initialValues={{
                                  gbShippingLastName:
                                    shippingStateInit?.last_name,
                                  gbShippingFirstName:
                                    shippingStateInit?.first_name,
                                  gbShippingCountry: shippingStateInit?.country,
                                  gbShippingPostcode:
                                    shippingStateInit?.postcode,
                                  gbShippingCity: shippingStateInit?.city,
                                  gbShippingState: shippingStateInit?.state,
                                  gbShippingAddress1:
                                    shippingStateInit?.address_1,
                                  gbShippingAddress2:
                                    shippingStateInit?.address_2,
                                  gbShippingPhone: shippingStateInit?.phone,
                                  gbShippingEmail: shippingStateInit?.email,
                                  gbShippingCompany: shippingStateInit?.company,

                                  // gbShippingLastName: shippingState?.last_name ?? shippingStateInit?.last_name,
                                  // gbShippingFirstName: shippingState?.first_name ?? shippingStateInit?.first_name,
                                  // gbShippingCountry: shippingState?.country ?? shippingStateInit?.country,
                                  // gbShippingPostcode: shippingState?.postcode ?? shippingStateInit?.postcode,
                                  // gbShippingCity: shippingState?.city ?? shippingStateInit?.city,
                                  // gbShippingState: shippingState?.state ?? shippingStateInit?.state,
                                  // gbShippingAddress1: shippingState?.address_1 ?? shippingStateInit?.address_1,
                                  // gbShippingAddress2: shippingState?.address_2 ?? shippingStateInit?.address_2,
                                  // gbShippingPhone: shippingState?.phone ?? shippingStateInit?.phone,
                                  // gbShippingEmail: shippingState?.email ?? shippingStateInit?.email,
                                  // gbShippingCompany: shippingState?.company ?? shippingStateInit?.company
                                }}
                              >
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowCheckbox}`}
                                  name="gbShippingCheckImportBillingInfo"
                                >
                                  <Checkbox
                                    className={style.gbCheckImportBillingInfo}
                                    checked={checkImportBillingInfoState}
                                    onChange={(e) => {
                                      checkImportBillingInfoOnChange(e);
                                    }}
                                  >
                                    同帳單資訊
                                  </Checkbox>
                                </Form.Item>
                                {!checkImportBillingInfoState ? (
                                  <section id="shippingInfo">
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowFirst} ${style.gbValidateRequired}`}
                                      id="gbShippingLastName"
                                      name="gbShippingLastName"
                                      label="姓氏 ( Last Name )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入姓氏",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          last_name: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input className={style.gbInputText} />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowLast} ${style.gbValidateRequired}`}
                                      id="gbShippingFirstName"
                                      name="gbShippingFirstName"
                                      label="名字 ( First Name )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入名字",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          first_name: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input className={style.gbInputText} />
                                    </Form.Item>
                                    {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowCheckbox}`}
                                    name="gbShippingCheckNeedTaxID"
                                  >
                                    <Checkbox checked={checkShowTaxId} onChange={(e)=>{checkShowTaxIdOnChange(e)}}>是否要打統編？</Checkbox>
                                  </Form.Item> */}
                                    {/* { checkShowTaxId ? (
                                    <>
                                      <Form.Item
                                        className={`${style.gbFormRow} ${style.gbFormRowWide}`}
                                        id="gbShippingCompany"
                                        name="gbShippingCompany"
                                        label="公司名稱 ( Company )（選填）"
                                        onChange={(e)=>setShippingState({ ...shippingState, company: e.target.value })}
                                      >
                                        <Input className={style.gbInputText} />
                                      </Form.Item>
                                      <Form.Item
                                        className={`${style.gbFormRow} ${style.gbFormRowWide}`}
                                        id="gbShippingTaxId"
                                        name="gbShippingTaxId"
                                        label="統一編號 ( Tax ID Number )（選填）"
                                        onChange={(e)=>setShippingState({ ...shippingState, taxIdNumber: e.target.value })}
                                      >
                                        <Input className={style.gbInputText} />
                                      </Form.Item> 
                                    </>  
                                  ) : (
                                    <></>
                                  )} */}
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbUpdateTotalsOnChange} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingCountry"
                                      name="gbShippingCountry"
                                      label="國家/地區或區域 ( Country / Region )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請選擇國家/地區或區域",
                                        },
                                      ]}
                                    >
                                      <Select
                                        aria-hidden="true"
                                        className={style.gbCountryToState}
                                        placeholder="選擇國家/地區或區域…"
                                        onChange={(value) => {
                                          setShippingState({
                                            ...shippingState,
                                            country: value,
                                          });
                                          checkShippingFields(value);
                                        }}
                                      >
                                        <Select.Option value="None">
                                          選擇國家/地區或區域…
                                        </Select.Option>
                                        <Select.Option value="AU">
                                          Australia
                                        </Select.Option>
                                        <Select.Option value="CA">
                                          Canada
                                        </Select.Option>
                                        <Select.Option value="FR">
                                          France
                                        </Select.Option>
                                        <Select.Option value="DE">
                                          Germany
                                        </Select.Option>
                                        <Select.Option value="IN">
                                          India
                                        </Select.Option>
                                        <Select.Option value="ID">
                                          Indonesia
                                        </Select.Option>
                                        <Select.Option value="IT">
                                          Italy
                                        </Select.Option>
                                        <Select.Option value="JP">
                                          Japan
                                        </Select.Option>
                                        <Select.Option value="MY">
                                          Malaysia
                                        </Select.Option>
                                        <Select.Option value="NZ">
                                          New Zealand
                                        </Select.Option>
                                        <Select.Option value="PH">
                                          Philippines
                                        </Select.Option>
                                        <Select.Option value="SG">
                                          Singapore
                                        </Select.Option>
                                        <Select.Option value="KR">
                                          South Korea
                                        </Select.Option>
                                        <Select.Option value="ES">
                                          Spain
                                        </Select.Option>
                                        <Select.Option value="TH">
                                          Thailand
                                        </Select.Option>
                                        <Select.Option value="GB">
                                          United Kingdom (UK)
                                        </Select.Option>
                                        <Select.Option value="US">
                                          United States (US)
                                        </Select.Option>
                                        <Select.Option value="VN">
                                          Vietnam
                                        </Select.Option>
                                        <Select.Option value="CN">
                                          中国
                                        </Select.Option>
                                        <Select.Option
                                          selected="selected"
                                          value="TW"
                                        >
                                          台灣
                                        </Select.Option>
                                        <Select.Option value="MO">
                                          澳門
                                        </Select.Option>
                                        <Select.Option value="HK">
                                          香港
                                        </Select.Option>
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowPostcode} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingPostcode"
                                      name="gbShippingPostcode"
                                      label="郵遞區號 ( Zip Code )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入郵遞區號",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          postcode: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextPostcode}`}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidateState}`}
                                      id="gbShippingState"
                                      name="gbShippingState"
                                      label="城市 ( State, City, Province )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入城市",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          state: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextCity}`}
                                        placeholder="台北市、台南市、或基隆市等..."
                                      />
                                    </Form.Item>
                                    {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbUpdateTotalsOnChange} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                    id="gbShippingState"
                                    name="gbShippingState"
                                    label="省份/城市 ( Province, City, State )"
                                    rules={[{ required: true, message: '請選擇省份/城市' }]}
                                    onChange={(e)=>{ setShippingState({ ...shippingState, state: e.target.value }); checkShippingFields(e); }}
                                  >
                                    <Select
                                      aria-hidden="true"
                                      className={style.gbCountryToState}
                                      placeholder="選擇省份/城市..."
                                    >
                                      {chinaCityOptions.map((option) => (
                                        <Select.Option key={option.value} value={option.value}>
                                          {option.label}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>                                   */}
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingCity"
                                      name="gbShippingCity"
                                      label="鄉鎮市區 ( District, Area, County )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入鄉鎮市區",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          city: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={style.gbInputText}
                                        placeholder="如：尖石鄉、竹南鎮、員林市、中正區等..."
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingAddress1"
                                      name="gbShippingAddress1"
                                      label="街道地址 ( Street Address )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入街道地址",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          address_1: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextAddress}`}
                                        placeholder="門牌號碼與街道名稱"
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide}`}
                                      id="gbShippingAddress2"
                                      name="gbShippingAddress2"
                                      label="地址第二行 ( 2nd Line Street Address )（選填）"
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          address_2: e.target.value,
                                        });
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextAddress}`}
                                        placeholder="公寓、套房、單元等 ( 選填 )"
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidatePhone}`}
                                      id="gbShippingPhone"
                                      name="gbShippingPhone"
                                      label="聯絡電話 ( Phone )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入聯絡電話",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          phone: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={style.gbInputText}
                                        placeholder="0912345678 或 02-12345678"
                                      />
                                    </Form.Item>
                                    {/* {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidateEmail}`}
                                    id="gbShippingEmail"
                                    name="gbShippingEmail"
                                    label="電子郵件 ( E-mail )"
                                    rules={[
                                      { required: true, message: '請輸入電子郵件' },
                                      { type: 'email', message: '請輸入有效的電子郵件地址' },
                                    ]}
                                    onChange={(e)=>{ setShippingState({ ...shippingState, email: e.target.value }); checkShippingFields(e); }}
                                  >
                                    <Input className={style.gbInputText} placeholder="如：username@domain.com" />
                                  </Form.Item>     */}
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowOrderNote}`}
                                      id="gbShippingOrderComments"
                                      name="gbShippingOrderComments"
                                      label="訂單備註"
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          order_comments: e.target.value,
                                        });
                                      }}
                                    >
                                      <Input.TextArea
                                        className={`${style.gbInputText} ${style.gbInputTextOrderNote}`}
                                        placeholder="訂單備註"
                                        rows={2}
                                      />
                                    </Form.Item>
                                    <Row className={style.gbBottomRow3}>
                                      <div className={style.gbErrMsg}>
                                        {shippingFormErrMsg}
                                      </div>
                                    </Row>
                                  </section>
                                ) : (
                                  <></>
                                )}
                              </Form>
                            </div>
                            <div className={style.gbCol2}></div>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </section>
                </Content>
              ) : shippingStateInit?.getValue &&
                !shippingState?.importBillingInfo ? ( // 狀態： true && false
                <Content
                  className={`${style.gbRowCourseImg} ${style.gbContentToolAddress}`}
                >
                  <section
                    className={`${style.gbColCourseImg} ${style.gbContentSecToolAddress}`}
                  >
                    {/* <div style={{display:"none"}}>{JSON.stringify(shippingState)}</div> */}
                    <Collapse
                      className={style.gbCollapseBillingForm}
                      defaultActiveKey={["shippingInfo"]}
                      collapsible="disabled"
                      ghost
                      expandIcon={({ isActive }) => <></>}
                      expandIconPosition="end"
                      onChange={(keys) => {
                        setActiveKey(keys); // 设置当前展开的面板
                        // if (!dataFetched && keys.includes('shippingInfo')) {
                        // setShowCollapse('shippingInfo');
                        getBillInfo(); // 在第一次展开时调用 getBillInfo() 来获取数据
                        // }
                      }}
                    >
                      <Panel
                        className={style.gbPanelBillingForm}
                        header={
                          <Title level={3} className={style.titleH3_5Tool}>
                            寄送資訊<br></br>(
                            注意力與執行力訓練課程、口肌訓練課程 )
                          </Title>
                        }
                        key={"shippingInfo"}
                      >
                        <div className={style.gbContentSecDivToolAddress}>
                          {/* <div className={style.gbUnderlineInputDiv}>
                              <input className={style.gbUnderlineInput} type="text" />
                            </div>    */}
                          <div className={style.gbContentSecDivDivToolAddress}>
                            <div className={style.gbCol1}>
                              <Form
                                className={style.gbForm}
                                name="customerDetailsForm"
                                onFinish={onFinish}
                                initialValues={{
                                  gbShippingLastName:
                                    shippingStateInit?.last_name,
                                  gbShippingFirstName:
                                    shippingStateInit?.first_name,
                                  gbShippingCountry: shippingStateInit?.country,
                                  gbShippingPostcode:
                                    shippingStateInit?.postcode,
                                  gbShippingCity: shippingStateInit?.city,
                                  gbShippingState: shippingStateInit?.state,
                                  gbShippingAddress1:
                                    shippingStateInit?.address_1,
                                  gbShippingAddress2:
                                    shippingStateInit?.address_2,
                                  gbShippingPhone: shippingStateInit?.phone,
                                  gbShippingEmail: shippingStateInit?.email,
                                  gbShippingCompany: shippingStateInit?.company,

                                  // gbShippingLastName: shippingState?.last_name ?? shippingStateInit?.last_name,
                                  // gbShippingFirstName: shippingState?.first_name ?? shippingStateInit?.first_name,
                                  // gbShippingCountry: shippingState?.country ?? shippingStateInit?.country,
                                  // gbShippingPostcode: shippingState?.postcode ?? shippingStateInit?.postcode,
                                  // gbShippingCity: shippingState?.city ?? shippingStateInit?.city,
                                  // gbShippingState: shippingState?.state ?? shippingStateInit?.state,
                                  // gbShippingAddress1: shippingState?.address_1 ?? shippingStateInit?.address_1,
                                  // gbShippingAddress2: shippingState?.address_2 ?? shippingStateInit?.address_2,
                                  // gbShippingPhone: shippingState?.phone ?? shippingStateInit?.phone,
                                  // gbShippingEmail: shippingState?.email ?? shippingStateInit?.email,
                                  // gbShippingCompany: shippingState?.company ?? shippingStateInit?.company
                                }}
                              >
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowCheckbox}`}
                                  name="gbShippingCheckImportBillingInfo"
                                >
                                  <Checkbox
                                    className={style.gbCheckImportBillingInfo}
                                    checked={checkImportBillingInfoState}
                                    onChange={(e) => {
                                      checkImportBillingInfoOnChange(e);
                                    }}
                                  >
                                    同帳單資訊
                                  </Checkbox>
                                </Form.Item>
                                {!checkImportBillingInfoState ? (
                                  <section id="shippingInfo">
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowFirst} ${style.gbValidateRequired}`}
                                      id="gbShippingLastName"
                                      name="gbShippingLastName"
                                      label="姓氏 ( Last Name )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入姓氏",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          last_name: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input className={style.gbInputText} />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowLast} ${style.gbValidateRequired}`}
                                      id="gbShippingFirstName"
                                      name="gbShippingFirstName"
                                      label="名字 ( First Name )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入名字",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          first_name: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input className={style.gbInputText} />
                                    </Form.Item>
                                    {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowCheckbox}`}
                                    name="gbShippingCheckNeedTaxID"
                                  >
                                    <Checkbox checked={checkShowTaxId} onChange={(e)=>{checkShowTaxIdOnChange(e)}}>是否要打統編？</Checkbox>
                                  </Form.Item> */}
                                    {/* { checkShowTaxId ? (
                                    <>
                                      <Form.Item
                                        className={`${style.gbFormRow} ${style.gbFormRowWide}`}
                                        id="gbShippingCompany"
                                        name="gbShippingCompany"
                                        label="公司名稱 ( Company )（選填）"
                                        onChange={(e)=>setShippingState({ ...shippingState, company: e.target.value })}
                                      >
                                        <Input className={style.gbInputText} />
                                      </Form.Item>
                                      <Form.Item
                                        className={`${style.gbFormRow} ${style.gbFormRowWide}`}
                                        id="gbShippingTaxId"
                                        name="gbShippingTaxId"
                                        label="統一編號 ( Tax ID Number )（選填）"
                                        onChange={(e)=>setShippingState({ ...shippingState, taxIdNumber: e.target.value })}
                                      >
                                        <Input className={style.gbInputText} />
                                      </Form.Item> 
                                    </>  
                                  ) : (
                                    <></>
                                  )} */}
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbUpdateTotalsOnChange} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingCountry"
                                      name="gbShippingCountry"
                                      label="國家/地區或區域 ( Country / Region )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請選擇國家/地區或區域",
                                        },
                                      ]}
                                    >
                                      <Select
                                        aria-hidden="true"
                                        className={style.gbCountryToState}
                                        placeholder="選擇國家/地區或區域…"
                                        onChange={(value) => {
                                          setShippingState({
                                            ...shippingState,
                                            country: value,
                                          });
                                          checkShippingFields(value);
                                        }}
                                      >
                                        <Select.Option value="None">
                                          選擇國家/地區或區域…
                                        </Select.Option>
                                        <Select.Option value="AU">
                                          Australia
                                        </Select.Option>
                                        <Select.Option value="CA">
                                          Canada
                                        </Select.Option>
                                        <Select.Option value="FR">
                                          France
                                        </Select.Option>
                                        <Select.Option value="DE">
                                          Germany
                                        </Select.Option>
                                        <Select.Option value="IN">
                                          India
                                        </Select.Option>
                                        <Select.Option value="ID">
                                          Indonesia
                                        </Select.Option>
                                        <Select.Option value="IT">
                                          Italy
                                        </Select.Option>
                                        <Select.Option value="JP">
                                          Japan
                                        </Select.Option>
                                        <Select.Option value="MY">
                                          Malaysia
                                        </Select.Option>
                                        <Select.Option value="NZ">
                                          New Zealand
                                        </Select.Option>
                                        <Select.Option value="PH">
                                          Philippines
                                        </Select.Option>
                                        <Select.Option value="SG">
                                          Singapore
                                        </Select.Option>
                                        <Select.Option value="KR">
                                          South Korea
                                        </Select.Option>
                                        <Select.Option value="ES">
                                          Spain
                                        </Select.Option>
                                        <Select.Option value="TH">
                                          Thailand
                                        </Select.Option>
                                        <Select.Option value="GB">
                                          United Kingdom (UK)
                                        </Select.Option>
                                        <Select.Option value="US">
                                          United States (US)
                                        </Select.Option>
                                        <Select.Option value="VN">
                                          Vietnam
                                        </Select.Option>
                                        <Select.Option value="CN">
                                          中国
                                        </Select.Option>
                                        <Select.Option
                                          selected="selected"
                                          value="TW"
                                        >
                                          台灣
                                        </Select.Option>
                                        <Select.Option value="MO">
                                          澳門
                                        </Select.Option>
                                        <Select.Option value="HK">
                                          香港
                                        </Select.Option>
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowPostcode} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingPostcode"
                                      name="gbShippingPostcode"
                                      label="郵遞區號 ( Zip Code )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入郵遞區號",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          postcode: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextPostcode}`}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidateState}`}
                                      id="gbShippingState"
                                      name="gbShippingState"
                                      label="城市 ( State, City, Province )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入城市",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          state: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextCity}`}
                                        placeholder="台北市、台南市、或基隆市等..."
                                      />
                                    </Form.Item>
                                    {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbUpdateTotalsOnChange} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                    id="gbShippingState"
                                    name="gbShippingState"
                                    label="省份/城市 ( Province, City, State )"
                                    rules={[{ required: true, message: '請選擇省份/城市' }]}
                                    onChange={(e)=>{ setShippingState({ ...shippingState, state: e.target.value }); checkShippingFields(e); }}
                                  >
                                    <Select
                                      aria-hidden="true"
                                      className={style.gbCountryToState}
                                      placeholder="選擇省份/城市..."
                                    >
                                      {chinaCityOptions.map((option) => (
                                        <Select.Option key={option.value} value={option.value}>
                                          {option.label}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item> */}
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingCity"
                                      name="gbShippingCity"
                                      label="鄉鎮市區 ( District, Area, County )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入鄉鎮市區",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          city: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={style.gbInputText}
                                        placeholder="如：尖石鄉、竹南鎮、員林市、中正區等..."
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingAddress1"
                                      name="gbShippingAddress1"
                                      label="街道地址 ( Street Address )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入街道地址",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          address_1: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextAddress}`}
                                        placeholder="門牌號碼與街道名稱"
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide}`}
                                      id="gbShippingAddress2"
                                      name="gbShippingAddress2"
                                      label="地址第二行 ( 2nd Line Street Address )（選填）"
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          address_2: e.target.value,
                                        });
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextAddress}`}
                                        placeholder="公寓、套房、單元等 ( 選填 )"
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidatePhone}`}
                                      id="gbShippingPhone"
                                      name="gbShippingPhone"
                                      label="聯絡電話 ( Phone )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入聯絡電話",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          phone: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={style.gbInputText}
                                        placeholder="0912345678 或 02-12345678"
                                      />
                                    </Form.Item>
                                    {/* {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidateEmail}`}
                                    id="gbShippingEmail"
                                    name="gbShippingEmail"
                                    label="電子郵件 ( E-mail )"
                                    rules={[
                                      { required: true, message: '請輸入電子郵件' },
                                      { type: 'email', message: '請輸入有效的電子郵件地址' },
                                    ]}
                                    onChange={(e)=>{ setShippingState({ ...shippingState, email: e.target.value }); checkShippingFields(e); }}
                                  >
                                    <Input className={style.gbInputText} placeholder="如：username@domain.com" />
                                  </Form.Item>     */}
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowOrderNote}`}
                                      id="gbShippingOrderComments"
                                      name="gbShippingOrderComments"
                                      label="訂單備註"
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          order_comments: e.target.value,
                                        });
                                      }}
                                    >
                                      <Input.TextArea
                                        className={`${style.gbInputText} ${style.gbInputTextOrderNote}`}
                                        placeholder="訂單備註"
                                        rows={2}
                                      />
                                    </Form.Item>
                                    <Row className={style.gbBottomRow3}>
                                      <div className={style.gbErrMsg}>
                                        {shippingFormErrMsg}
                                      </div>
                                    </Row>
                                  </section>
                                ) : (
                                  <></>
                                )}
                              </Form>
                            </div>
                            <div className={style.gbCol2}></div>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </section>
                </Content>
              ) : !shippingStateInit?.getValue &&
                shippingState?.importBillingInfo ? ( // 狀態： false && true
                <Content
                  className={`${style.gbRowCourseImg} ${style.gbContentToolAddress}`}
                >
                  <section
                    className={`${style.gbColCourseImg} ${style.gbContentSecToolAddress}`}
                  >
                    <div style={{ display: "none" }}>
                      {JSON.stringify(shippingState)}
                    </div>
                    <Collapse
                      className={style.gbCollapseBillingForm}
                      defaultActiveKey={["shippingInfo"]}
                      collapsible="disabled"
                      ghost
                      expandIcon={({ isActive }) => <></>}
                      expandIconPosition="end"
                      onChange={(keys) => {
                        setActiveKey(keys); // 设置当前展开的面板
                        // if (!dataFetched && keys.includes('shippingInfo')) {
                        // setShowCollapse('shippingInfo');
                        getBillInfo(); // 在第一次展开时调用 getBillInfo() 来获取数据
                        // }
                      }}
                    >
                      <Panel
                        className={style.gbPanelBillingForm}
                        header={
                          <Title level={3} className={style.titleH3_5Tool}>
                            寄送資訊<br></br>(
                            注意力與執行力訓練課程、口肌訓練課程 )
                          </Title>
                        }
                        key={"shippingInfo"}
                      >
                        <div className={style.gbContentSecDivToolAddress}>
                          {/* <div className={style.gbUnderlineInputDiv}>
                              <input className={style.gbUnderlineInput} type="text" />
                            </div>    */}
                          <div className={style.gbContentSecDivDivToolAddress}>
                            <div className={style.gbCol1}>
                              <Form
                                className={style.gbForm}
                                name="customerDetailsForm"
                                onFinish={onFinish}
                                initialValues={{
                                  // gbShippingLastName: shippingStateInit?.last_name,
                                  // gbShippingFirstName: shippingStateInit?.first_name,
                                  // gbShippingCountry: shippingStateInit?.country,
                                  // gbShippingPostcode: shippingStateInit?.postcode,
                                  // gbShippingCity: shippingStateInit?.city,
                                  // gbShippingState: shippingStateInit?.state,
                                  // gbShippingAddress1: shippingStateInit?.address_1,
                                  // gbShippingAddress2: shippingStateInit?.address_2,
                                  // gbShippingPhone: shippingStateInit?.phone,
                                  // gbShippingEmail: shippingStateInit?.email,
                                  // gbShippingCompany: shippingStateInit?.company

                                  gbShippingLastName:
                                    shippingState?.last_name ??
                                    shippingStateInit?.last_name,
                                  gbShippingFirstName:
                                    shippingState?.first_name ??
                                    shippingStateInit?.first_name,
                                  gbShippingCountry:
                                    shippingState?.country ??
                                    shippingStateInit?.country,
                                  gbShippingPostcode:
                                    shippingState?.postcode ??
                                    shippingStateInit?.postcode,
                                  gbShippingCity:
                                    shippingState?.city ??
                                    shippingStateInit?.city,
                                  gbShippingState:
                                    shippingState?.state ??
                                    shippingStateInit?.state,
                                  gbShippingAddress1:
                                    shippingState?.address_1 ??
                                    shippingStateInit?.address_1,
                                  gbShippingAddress2:
                                    shippingState?.address_2 ??
                                    shippingStateInit?.address_2,
                                  gbShippingPhone:
                                    shippingState?.phone ??
                                    shippingStateInit?.phone,
                                  gbShippingEmail:
                                    shippingState?.email ??
                                    shippingStateInit?.email,
                                  gbShippingCompany:
                                    shippingState?.company ??
                                    shippingStateInit?.company,
                                }}
                              >
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowCheckbox}`}
                                  name="gbShippingCheckImportBillingInfo"
                                >
                                  <Checkbox
                                    className={style.gbCheckImportBillingInfo}
                                    checked={checkImportBillingInfoState}
                                    onChange={(e) => {
                                      checkImportBillingInfoOnChange(e);
                                    }}
                                  >
                                    同帳單資訊
                                  </Checkbox>
                                </Form.Item>
                                {!checkImportBillingInfoState ? (
                                  <section id="shippingInfo">
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowFirst} ${style.gbValidateRequired}`}
                                      id="gbShippingLastName"
                                      name="gbShippingLastName"
                                      label="姓氏 ( Last Name )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入姓氏",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          last_name: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input className={style.gbInputText} />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowLast} ${style.gbValidateRequired}`}
                                      id="gbShippingFirstName"
                                      name="gbShippingFirstName"
                                      label="名字 ( First Name )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入名字",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          first_name: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input className={style.gbInputText} />
                                    </Form.Item>
                                    {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowCheckbox}`}
                                    name="gbShippingCheckNeedTaxID"
                                  >
                                    <Checkbox checked={checkShowTaxId} onChange={(e)=>{checkShowTaxIdOnChange(e)}}>是否要打統編？</Checkbox>
                                  </Form.Item> */}
                                    {/* { checkShowTaxId ? (
                                    <>
                                      <Form.Item
                                        className={`${style.gbFormRow} ${style.gbFormRowWide}`}
                                        id="gbShippingCompany"
                                        name="gbShippingCompany"
                                        label="公司名稱 ( Company )（選填）"
                                        onChange={(e)=>setShippingState({ ...shippingState, company: e.target.value })}
                                      >
                                        <Input className={style.gbInputText} />
                                      </Form.Item>
                                      <Form.Item
                                        className={`${style.gbFormRow} ${style.gbFormRowWide}`}
                                        id="gbShippingTaxId"
                                        name="gbShippingTaxId"
                                        label="統一編號 ( Tax ID Number )（選填）"
                                        onChange={(e)=>setShippingState({ ...shippingState, taxIdNumber: e.target.value })}
                                      >
                                        <Input className={style.gbInputText} />
                                      </Form.Item> 
                                    </>  
                                  ) : (
                                    <></>
                                  )} */}
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbUpdateTotalsOnChange} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingCountry"
                                      name="gbShippingCountry"
                                      label="國家/地區或區域 ( Country / Region )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請選擇國家/地區或區域",
                                        },
                                      ]}
                                    >
                                      <Select
                                        aria-hidden="true"
                                        className={style.gbCountryToState}
                                        placeholder="選擇國家/地區或區域…"
                                        onChange={(value) => {
                                          setShippingState({
                                            ...shippingState,
                                            country: value,
                                          });
                                          checkShippingFields(value);
                                        }}
                                      >
                                        <Select.Option value="None">
                                          選擇國家/地區或區域…
                                        </Select.Option>
                                        <Select.Option value="AU">
                                          Australia
                                        </Select.Option>
                                        <Select.Option value="CA">
                                          Canada
                                        </Select.Option>
                                        <Select.Option value="FR">
                                          France
                                        </Select.Option>
                                        <Select.Option value="DE">
                                          Germany
                                        </Select.Option>
                                        <Select.Option value="IN">
                                          India
                                        </Select.Option>
                                        <Select.Option value="ID">
                                          Indonesia
                                        </Select.Option>
                                        <Select.Option value="IT">
                                          Italy
                                        </Select.Option>
                                        <Select.Option value="JP">
                                          Japan
                                        </Select.Option>
                                        <Select.Option value="MY">
                                          Malaysia
                                        </Select.Option>
                                        <Select.Option value="NZ">
                                          New Zealand
                                        </Select.Option>
                                        <Select.Option value="PH">
                                          Philippines
                                        </Select.Option>
                                        <Select.Option value="SG">
                                          Singapore
                                        </Select.Option>
                                        <Select.Option value="KR">
                                          South Korea
                                        </Select.Option>
                                        <Select.Option value="ES">
                                          Spain
                                        </Select.Option>
                                        <Select.Option value="TH">
                                          Thailand
                                        </Select.Option>
                                        <Select.Option value="GB">
                                          United Kingdom (UK)
                                        </Select.Option>
                                        <Select.Option value="US">
                                          United States (US)
                                        </Select.Option>
                                        <Select.Option value="VN">
                                          Vietnam
                                        </Select.Option>
                                        <Select.Option value="CN">
                                          中国
                                        </Select.Option>
                                        <Select.Option
                                          selected="selected"
                                          value="TW"
                                        >
                                          台灣
                                        </Select.Option>
                                        <Select.Option value="MO">
                                          澳門
                                        </Select.Option>
                                        <Select.Option value="HK">
                                          香港
                                        </Select.Option>
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowPostcode} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingPostcode"
                                      name="gbShippingPostcode"
                                      label="郵遞區號 ( Zip Code )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入郵遞區號",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          postcode: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextPostcode}`}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidateState}`}
                                      id="gbShippingState"
                                      name="gbShippingState"
                                      label="城市 ( State, City, Province )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入城市",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          state: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextCity}`}
                                        placeholder="台北市、台南市、或基隆市等..."
                                      />
                                    </Form.Item>
                                    {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbUpdateTotalsOnChange} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                    id="gbShippingState"
                                    name="gbShippingState"
                                    label="省份/城市 ( Province, City, State )"
                                    rules={[{ required: true, message: '請選擇省份/城市' }]}
                                    onChange={(e)=>{ setShippingState({ ...shippingState, state: e.target.value }); checkShippingFields(e); }}
                                  >
                                    <Select
                                      aria-hidden="true"
                                      className={style.gbCountryToState}
                                      placeholder="選擇省份/城市..."
                                    >
                                      {chinaCityOptions.map((option) => (
                                        <Select.Option key={option.value} value={option.value}>
                                          {option.label}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item> */}
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingCity"
                                      name="gbShippingCity"
                                      label="鄉鎮市區 ( District, Area, County )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入鄉鎮市區",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          city: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={style.gbInputText}
                                        placeholder="如：尖石鄉、竹南鎮、員林市、中正區等..."
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingAddress1"
                                      name="gbShippingAddress1"
                                      label="街道地址 ( Street Address )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入街道地址",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          address_1: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextAddress}`}
                                        placeholder="門牌號碼與街道名稱"
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide}`}
                                      id="gbShippingAddress2"
                                      name="gbShippingAddress2"
                                      label="地址第二行 ( 2nd Line Street Address )（選填）"
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          address_2: e.target.value,
                                        });
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextAddress}`}
                                        placeholder="公寓、套房、單元等 ( 選填 )"
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidatePhone}`}
                                      id="gbShippingPhone"
                                      name="gbShippingPhone"
                                      label="聯絡電話 ( Phone )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入聯絡電話",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          phone: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={style.gbInputText}
                                        placeholder="0912345678 或 02-12345678"
                                      />
                                    </Form.Item>
                                    {/* {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidateEmail}`}
                                    id="gbShippingEmail"
                                    name="gbShippingEmail"
                                    label="電子郵件 ( E-mail )"
                                    rules={[
                                      { required: true, message: '請輸入電子郵件' },
                                      { type: 'email', message: '請輸入有效的電子郵件地址' },
                                    ]}
                                    onChange={(e)=>{ setShippingState({ ...shippingState, email: e.target.value }); checkShippingFields(e); }}
                                  >
                                    <Input className={style.gbInputText} placeholder="如：username@domain.com" />
                                  </Form.Item>     */}
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowOrderNote}`}
                                      id="gbShippingOrderComments"
                                      name="gbShippingOrderComments"
                                      label="訂單備註"
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          order_comments: e.target.value,
                                        });
                                      }}
                                    >
                                      <Input.TextArea
                                        className={`${style.gbInputText} ${style.gbInputTextOrderNote}`}
                                        placeholder="訂單備註"
                                        rows={2}
                                      />
                                    </Form.Item>
                                    <Row className={style.gbBottomRow3}>
                                      <div className={style.gbErrMsg}>
                                        {shippingFormErrMsg}
                                      </div>
                                    </Row>
                                  </section>
                                ) : (
                                  <></>
                                )}
                              </Form>
                            </div>
                            <div className={style.gbCol2}></div>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </section>
                </Content>
              ) : (
                // 狀態： true && true
                <Content
                  className={`${style.gbRowCourseImg} ${style.gbContentToolAddress}`}
                >
                  <section
                    className={`${style.gbColCourseImg} ${style.gbContentSecToolAddress}`}
                  >
                    <div style={{ display: "none" }}>
                      {JSON.stringify(shippingState)}
                    </div>
                    <Collapse
                      className={style.gbCollapseBillingForm}
                      defaultActiveKey={["shippingInfo"]}
                      collapsible="disabled"
                      ghost
                      expandIcon={({ isActive }) => <></>}
                      expandIconPosition="end"
                      onChange={(keys) => {
                        setActiveKey(keys); // 设置当前展开的面板
                        // if (!dataFetched && keys.includes('shippingInfo')) {
                        // setShowCollapse('shippingInfo');
                        getBillInfo(); // 在第一次展开时调用 getBillInfo() 来获取数据
                        // }
                      }}
                    >
                      <Panel
                        className={style.gbPanelBillingForm}
                        header={
                          <Title level={3} className={style.titleH3_5Tool}>
                            寄送資訊<br></br>(
                            注意力與執行力訓練課程、口肌訓練課程 )
                          </Title>
                        }
                        key={"shippingInfo"}
                      >
                        <div className={style.gbContentSecDivToolAddress}>
                          {/* <div className={style.gbUnderlineInputDiv}>
                              <input className={style.gbUnderlineInput} type="text" />
                            </div>    */}
                          <div className={style.gbContentSecDivDivToolAddress}>
                            <div className={style.gbCol1}>
                              <Form
                                className={style.gbForm}
                                name="customerDetailsForm"
                                onFinish={onFinish}
                                initialValues={{
                                  // gbShippingLastName: shippingStateInit?.last_name,
                                  // gbShippingFirstName: shippingStateInit?.first_name,
                                  // gbShippingCountry: shippingStateInit?.country,
                                  // gbShippingPostcode: shippingStateInit?.postcode,
                                  // gbShippingCity: shippingStateInit?.city,
                                  // gbShippingState: shippingStateInit?.state,
                                  // gbShippingAddress1: shippingStateInit?.address_1,
                                  // gbShippingAddress2: shippingStateInit?.address_2,
                                  // gbShippingPhone: shippingStateInit?.phone,
                                  // gbShippingEmail: shippingStateInit?.email,
                                  // gbShippingCompany: shippingStateInit?.company

                                  gbShippingLastName:
                                    shippingState?.last_name ??
                                    shippingStateInit?.last_name,
                                  gbShippingFirstName:
                                    shippingState?.first_name ??
                                    shippingStateInit?.first_name,
                                  gbShippingCountry:
                                    shippingState?.country ??
                                    shippingStateInit?.country,
                                  gbShippingPostcode:
                                    shippingState?.postcode ??
                                    shippingStateInit?.postcode,
                                  gbShippingCity:
                                    shippingState?.city ??
                                    shippingStateInit?.city,
                                  gbShippingState:
                                    shippingState?.state ??
                                    shippingStateInit?.state,
                                  gbShippingAddress1:
                                    shippingState?.address_1 ??
                                    shippingStateInit?.address_1,
                                  gbShippingAddress2:
                                    shippingState?.address_2 ??
                                    shippingStateInit?.address_2,
                                  gbShippingPhone:
                                    shippingState?.phone ??
                                    shippingStateInit?.phone,
                                  gbShippingEmail:
                                    shippingState?.email ??
                                    shippingStateInit?.email,
                                  gbShippingCompany:
                                    shippingState?.company ??
                                    shippingStateInit?.company,
                                }}
                              >
                                <Form.Item
                                  className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowCheckbox}`}
                                  name="gbShippingCheckImportBillingInfo"
                                >
                                  <Checkbox
                                    className={style.gbCheckImportBillingInfo}
                                    checked={checkImportBillingInfoState}
                                    onChange={(e) => {
                                      checkImportBillingInfoOnChange(e);
                                    }}
                                  >
                                    同帳單資訊
                                  </Checkbox>
                                </Form.Item>
                                {!checkImportBillingInfoState ? (
                                  <section id="shippingInfo">
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowFirst} ${style.gbValidateRequired}`}
                                      id="gbShippingLastName"
                                      name="gbShippingLastName"
                                      label="姓氏 ( Last Name )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入姓氏",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          last_name: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input className={style.gbInputText} />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowLast} ${style.gbValidateRequired}`}
                                      id="gbShippingFirstName"
                                      name="gbShippingFirstName"
                                      label="名字 ( First Name )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入名字",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          first_name: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input className={style.gbInputText} />
                                    </Form.Item>
                                    {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowCheckbox}`}
                                    name="gbShippingCheckNeedTaxID"
                                  >
                                    <Checkbox checked={checkShowTaxId} onChange={(e)=>{checkShowTaxIdOnChange(e)}}>是否要打統編？</Checkbox>
                                  </Form.Item> */}
                                    {/* { checkShowTaxId ? (
                                    <>
                                      <Form.Item
                                        className={`${style.gbFormRow} ${style.gbFormRowWide}`}
                                        id="gbShippingCompany"
                                        name="gbShippingCompany"
                                        label="公司名稱 ( Company )（選填）"
                                        onChange={(e)=>setShippingState({ ...shippingState, company: e.target.value })}
                                      >
                                        <Input className={style.gbInputText} />
                                      </Form.Item>
                                      <Form.Item
                                        className={`${style.gbFormRow} ${style.gbFormRowWide}`}
                                        id="gbShippingTaxId"
                                        name="gbShippingTaxId"
                                        label="統一編號 ( Tax ID Number )（選填）"
                                        onChange={(e)=>setShippingState({ ...shippingState, taxIdNumber: e.target.value })}
                                      >
                                        <Input className={style.gbInputText} />
                                      </Form.Item> 
                                    </>  
                                  ) : (
                                    <></>
                                  )} */}
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbUpdateTotalsOnChange} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingCountry"
                                      name="gbShippingCountry"
                                      label="國家/地區或區域 ( Country / Region )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請選擇國家/地區或區域",
                                        },
                                      ]}
                                    >
                                      <Select
                                        aria-hidden="true"
                                        className={style.gbCountryToState}
                                        placeholder="選擇國家/地區或區域…"
                                        onChange={(value) => {
                                          setShippingState({
                                            ...shippingState,
                                            country: value,
                                          });
                                          checkShippingFields(value);
                                        }}
                                      >
                                        <Select.Option value="None">
                                          選擇國家/地區或區域…
                                        </Select.Option>
                                        <Select.Option value="AU">
                                          Australia
                                        </Select.Option>
                                        <Select.Option value="CA">
                                          Canada
                                        </Select.Option>
                                        <Select.Option value="FR">
                                          France
                                        </Select.Option>
                                        <Select.Option value="DE">
                                          Germany
                                        </Select.Option>
                                        <Select.Option value="IN">
                                          India
                                        </Select.Option>
                                        <Select.Option value="ID">
                                          Indonesia
                                        </Select.Option>
                                        <Select.Option value="IT">
                                          Italy
                                        </Select.Option>
                                        <Select.Option value="JP">
                                          Japan
                                        </Select.Option>
                                        <Select.Option value="MY">
                                          Malaysia
                                        </Select.Option>
                                        <Select.Option value="NZ">
                                          New Zealand
                                        </Select.Option>
                                        <Select.Option value="PH">
                                          Philippines
                                        </Select.Option>
                                        <Select.Option value="SG">
                                          Singapore
                                        </Select.Option>
                                        <Select.Option value="KR">
                                          South Korea
                                        </Select.Option>
                                        <Select.Option value="ES">
                                          Spain
                                        </Select.Option>
                                        <Select.Option value="TH">
                                          Thailand
                                        </Select.Option>
                                        <Select.Option value="GB">
                                          United Kingdom (UK)
                                        </Select.Option>
                                        <Select.Option value="US">
                                          United States (US)
                                        </Select.Option>
                                        <Select.Option value="VN">
                                          Vietnam
                                        </Select.Option>
                                        <Select.Option value="CN">
                                          中国
                                        </Select.Option>
                                        <Select.Option
                                          selected="selected"
                                          value="TW"
                                        >
                                          台灣
                                        </Select.Option>
                                        <Select.Option value="MO">
                                          澳門
                                        </Select.Option>
                                        <Select.Option value="HK">
                                          香港
                                        </Select.Option>
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowPostcode} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingPostcode"
                                      name="gbShippingPostcode"
                                      label="郵遞區號 ( Zip Code )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入郵遞區號",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          postcode: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextPostcode}`}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidateState}`}
                                      id="gbShippingState"
                                      name="gbShippingState"
                                      label="城市 ( State, City, Province )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入城市",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          state: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextCity}`}
                                        placeholder="台北市、台南市、或基隆市等..."
                                      />
                                    </Form.Item>
                                    {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbUpdateTotalsOnChange} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                    id="gbShippingState"
                                    name="gbShippingState"
                                    label="省份/城市 ( Province, City, State )"
                                    rules={[{ required: true, message: '請選擇省份/城市' }]}
                                    onChange={(e)=>{ setShippingState({ ...shippingState, state: e.target.value }); checkShippingFields(e); }}
                                  >
                                    <Select
                                      aria-hidden="true"
                                      className={style.gbCountryToState}
                                      placeholder="選擇省份/城市..."
                                    >
                                      {chinaCityOptions.map((option) => (
                                        <Select.Option key={option.value} value={option.value}>
                                          {option.label}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item> */}
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingCity"
                                      name="gbShippingCity"
                                      label="鄉鎮市區 ( District, Area, County )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入鄉鎮市區",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          city: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={style.gbInputText}
                                        placeholder="如：尖石鄉、竹南鎮、員林市、中正區等..."
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide} ${style.gbValidateRequired}`}
                                      id="gbShippingAddress1"
                                      name="gbShippingAddress1"
                                      label="街道地址 ( Street Address )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入街道地址",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          address_1: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextAddress}`}
                                        placeholder="門牌號碼與街道名稱"
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbAddressField} ${style.gbFormRowWide}`}
                                      id="gbShippingAddress2"
                                      name="gbShippingAddress2"
                                      label="地址第二行 ( 2nd Line Street Address )（選填）"
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          address_2: e.target.value,
                                        });
                                      }}
                                    >
                                      <Input
                                        className={`${style.gbInputText} ${style.gbInputTextAddress}`}
                                        placeholder="公寓、套房、單元等 ( 選填 )"
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidatePhone}`}
                                      id="gbShippingPhone"
                                      name="gbShippingPhone"
                                      label="聯絡電話 ( Phone )"
                                      rules={[
                                        {
                                          required: true,
                                          message: "請輸入聯絡電話",
                                        },
                                      ]}
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          phone: e.target.value,
                                        });
                                        checkShippingFields(e);
                                      }}
                                    >
                                      <Input
                                        className={style.gbInputText}
                                        placeholder="0912345678 或 02-12345678"
                                      />
                                    </Form.Item>
                                    {/* {/* <Form.Item
                                    className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbValidateRequired} ${style.gbValidateEmail}`}
                                    id="gbShippingEmail"
                                    name="gbShippingEmail"
                                    label="電子郵件 ( E-mail )"
                                    rules={[
                                      { required: true, message: '請輸入電子郵件' },
                                      { type: 'email', message: '請輸入有效的電子郵件地址' },
                                    ]}
                                    onChange={(e)=>{ setShippingState({ ...shippingState, email: e.target.value }); checkShippingFields(e); }}
                                  >
                                    <Input className={style.gbInputText} placeholder="如：username@domain.com" />
                                  </Form.Item>     */}
                                    <Form.Item
                                      className={`${style.gbFormRow} ${style.gbFormRowWide} ${style.gbFormRowOrderNote}`}
                                      id="gbShippingOrderComments"
                                      name="gbShippingOrderComments"
                                      label="訂單備註"
                                      onChange={(e) => {
                                        setShippingState({
                                          ...shippingState,
                                          order_comments: e.target.value,
                                        });
                                      }}
                                    >
                                      <Input.TextArea
                                        className={`${style.gbInputText} ${style.gbInputTextOrderNote}`}
                                        placeholder="訂單備註"
                                        rows={2}
                                      />
                                    </Form.Item>
                                    <Row className={style.gbBottomRow3}>
                                      <div className={style.gbErrMsg}>
                                        {shippingFormErrMsg}
                                      </div>
                                    </Row>
                                  </section>
                                ) : (
                                  <></>
                                )}
                              </Form>
                            </div>
                            <div className={style.gbCol2}></div>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </section>
                </Content>
              )}

              <Content
                className={`${style.gbRowCourseImg} ${style.gbContentBox}`}
              >
                <div className={`${style.gbContentBoxTitle}`}>
                  <Title level={3} className={style.titleH3_5Tool}>
                    信用卡資訊
                  </Title>
                  <Title level={3} className={style.titleH3_5Tool}>
                    ( 組團時只會進行授權，成團後才會進行請款 )
                  </Title>
                </div>
                <section
                  className={`${style.gbColCourseImg} ${style.gbContentSecTapPay}`}
                >
                  <TapPay></TapPay>
                </section>
              </Content>
              <Content
                className={`${style.gbRowCourseImg} ${style.gbContentBox}`}
              >
                <Col className={style.gbColCourseImg}>
                  <div
                    className={`${style.gbColCourseDescription} ${style.gbColCourseTotal}`}
                  >
                    {/* ids of courses
                     *   33589 嬰幼兒 父母必修課
                     *   20307 嬰幼兒 感覺統合啟蒙課程
                     *   100358 兒童 感覺統合訓練課程
                     *   60464 兒童 注意力與執行力訓練課程( 附教具 )
                     *   231206 兒童/成人 玩美臉型自信開口 全方位口肌訓練課程( 附教具 )
                     */}
                    {userProductsArray?.includes("33589") ? (
                      <div className={`${style.titleH3_5SubtotalDiv}`}>
                        {checkoutProductPriceInfoArray
                          ?.filter((e1) => e1?.id === "33589")
                          ?.map((item1, idx1) => {
                            return (
                              <>
                                {/* { coursePidDiscountPriceObjectMultiplyMembershipDiscountArray?.filter(e2 => e2?.pid === '33589')?.map((item2, idx2) => {
                                return (
                                  <> */}
                                <Title
                                  level={3}
                                  className={`${style.titleH3_5Tool} ${style.titleH3_5Total} ${style.titleH3_5Subtotal}`}
                                >
                                  <div
                                    className={`${style.gbCoursePriceTitle}`}
                                  >
                                    嬰幼兒 父母必修課
                                  </div>
                                  <span className={style.gbTotalOuter1}>
                                    <span className={style.gbTotal}>
                                      {item1?.discount / 10}
                                    </span>
                                    折
                                  </span>
                                  <span
                                    className={`${style.gbTotalOuter2} ${style.gbTotalOuter2Price}`}
                                  >
                                    <span
                                      className={`${style.gbTotal} ${style.gbProductPrice}`}
                                    >
                                      {item1?.amount}元
                                    </span>
                                    <span
                                      className={`${style.gbTotal} ${style.gbProductDiscountPrice}`}
                                    >
                                      {item1?.amount - item1?.discountPrice}元
                                    </span>
                                  </span>
                                </Title>

                                {/* </>
                                )
                              })} */}
                              </>
                            );
                          })}
                      </div>
                    ) : (
                      <></>
                    )}
                    {userProductsArray?.includes("20307") ? (
                      <div className={`${style.titleH3_5SubtotalDiv}`}>
                        {checkoutProductPriceInfoArray
                          ?.filter((e1) => e1?.id === "20307")
                          ?.map((item1, idx1) => {
                            return (
                              <>
                                {/* { coursePidDiscountPriceObjectMultiplyMembershipDiscountArray?.filter(e2 => e2?.pid === '20307')?.map((item2, idx2) => {
                                return (
                                  <> */}
                                <Title
                                  level={3}
                                  className={`${style.titleH3_5Tool} ${style.titleH3_5Total} ${style.titleH3_5Subtotal}`}
                                >
                                  <div
                                    className={`${style.gbCoursePriceTitle}`}
                                  >
                                    嬰幼兒 感覺統合啟蒙課程
                                  </div>
                                  <span className={style.gbTotalOuter1}>
                                    <span className={style.gbTotal}>
                                      {item1?.discount / 10}
                                    </span>
                                    折
                                  </span>
                                  <span
                                    className={`${style.gbTotalOuter2} ${style.gbTotalOuter2Price}`}
                                  >
                                    <span
                                      className={`${style.gbTotal} ${style.gbProductPrice}`}
                                    >
                                      {item1?.amount}元
                                    </span>
                                    <span
                                      className={`${style.gbTotal} ${style.gbProductDiscountPrice}`}
                                    >
                                      {item1?.amount - item1?.discountPrice}元
                                    </span>
                                  </span>
                                </Title>

                                {/* </>
                                )
                              })} */}
                              </>
                            );
                          })}
                      </div>
                    ) : (
                      <></>
                    )}
                    {userProductsArray?.includes("100358") ? (
                      <div className={`${style.titleH3_5SubtotalDiv}`}>
                        {checkoutProductPriceInfoArray
                          ?.filter((e1) => e1?.id === "100358")
                          ?.map((item1, idx1) => {
                            return (
                              <>
                                {/* { coursePidDiscountPriceObjectMultiplyMembershipDiscountArray?.filter(e2 => e2?.pid === '100358')?.map((item2, idx2) => {
                                return (
                                  <> */}
                                <Title
                                  level={3}
                                  className={`${style.titleH3_5Tool} ${style.titleH3_5Total} ${style.titleH3_5Subtotal}`}
                                >
                                  <div
                                    className={`${style.gbCoursePriceTitle}`}
                                  >
                                    兒童 感覺統合訓練課程
                                  </div>
                                  <span className={style.gbTotalOuter1}>
                                    <span className={style.gbTotal}>
                                      {item1?.discount / 10}
                                    </span>
                                    折
                                  </span>
                                  <span
                                    className={`${style.gbTotalOuter2} ${style.gbTotalOuter2Price}`}
                                  >
                                    <span
                                      className={`${style.gbTotal} ${style.gbProductPrice}`}
                                    >
                                      {item1?.amount}元
                                    </span>
                                    <span
                                      className={`${style.gbTotal} ${style.gbProductDiscountPrice}`}
                                    >
                                      {item1?.amount - item1?.discountPrice}元
                                    </span>
                                  </span>
                                </Title>

                                {/* </>
                                )
                              })} */}
                              </>
                            );
                          })}
                      </div>
                    ) : (
                      <></>
                    )}
                    {userProductsArray?.includes("60464") ? (
                      <div className={`${style.titleH3_5SubtotalDiv}`}>
                        {checkoutProductPriceInfoArray
                          ?.filter((e1) => e1?.id === "60464")
                          ?.map((item1, idx1) => {
                            return (
                              <>
                                {/* { coursePidDiscountPriceObjectMultiplyMembershipDiscountArray?.filter(e2 => e2?.pid === '60464')?.map((item2, idx2) => {
                                return (
                                  <> */}
                                <Title
                                  level={3}
                                  className={`${style.titleH3_5Tool} ${style.titleH3_5Total} ${style.titleH3_5Subtotal}`}
                                >
                                  <div
                                    className={`${style.gbCoursePriceTitle}`}
                                  >
                                    兒童 注意力與執行力訓練課程( 附教具 )
                                  </div>
                                  <span className={style.gbTotalOuter1}>
                                    <span className={style.gbTotal}>
                                      {item1?.discount / 10}
                                    </span>
                                    折
                                  </span>
                                  <span
                                    className={`${style.gbTotalOuter2} ${style.gbTotalOuter2Price}`}
                                  >
                                    <span
                                      className={`${style.gbTotal} ${style.gbProductPrice}`}
                                    >
                                      {item1?.amount}元
                                    </span>
                                    <span
                                      className={`${style.gbTotal} ${style.gbProductDiscountPrice}`}
                                    >
                                      {item1?.amount - item1?.discountPrice}元
                                    </span>
                                  </span>
                                </Title>

                                {/* </>
                                )
                              })} */}
                              </>
                            );
                          })}
                      </div>
                    ) : (
                      <></>
                    )}
                    {userProductsArray?.includes("231206") ? (
                      <div className={`${style.titleH3_5SubtotalDiv}`}>
                        {checkoutProductPriceInfoArray
                          ?.filter((e1) => e1?.id === "231206")
                          ?.map((item1, idx1) => {
                            return (
                              <>
                                {/* { coursePidDiscountPriceObjectMultiplyMembershipDiscountArray?.filter(e2 => e2?.pid === '231206')?.map((item2, idx2) => {
                                return (
                                  <> */}
                                <Title
                                  level={3}
                                  className={`${style.titleH3_5Tool} ${style.titleH3_5Total} ${style.titleH3_5Subtotal}`}
                                >
                                  <div
                                    className={`${style.gbCoursePriceTitle}`}
                                  >
                                    兒童/成人 玩美臉型自信開口
                                    全方位口肌訓練課程( 附教具 )
                                  </div>
                                  <span className={style.gbTotalOuter1}>
                                    <span className={style.gbTotal}>
                                      {item1?.discount / 10}
                                    </span>
                                    折
                                  </span>
                                  <span
                                    className={`${style.gbTotalOuter2} ${style.gbTotalOuter2Price}`}
                                  >
                                    <span
                                      className={`${style.gbTotal} ${style.gbProductPrice}`}
                                    >
                                      {item1?.amount}元
                                    </span>
                                    <span
                                      className={`${style.gbTotal} ${style.gbProductDiscountPrice}`}
                                    >
                                      {item1?.amount - item1?.discountPrice}元
                                    </span>
                                  </span>
                                </Title>
                                {/* </>
                                )
                              })} */}
                              </>
                            );
                          })}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className={`${style.gbBreakLine}`}></div>
                    {!!checkoutPriceInfo?.subTotal ? (
                      <Title
                        level={3}
                        className={`${style.titleH3_5Tool} ${style.titleH3_5Total} ${style.titleH3_5Subtotal}`}
                      >
                        <div className={`${style.gbCoursePriceTitle}`}>
                          小計
                        </div>
                        <span className={style.gbTotalOuter1}></span>
                        <span className={style.gbTotalOuter2}>
                          <span
                            className={`${style.gbTotal} ${style.gbProductDiscountPrice}`}
                          >
                            {checkoutPriceInfo?.subTotal}
                          </span>
                          元
                        </span>
                      </Title>
                    ) : (
                      <Title
                        level={3}
                        className={`${style.titleH3_5Tool} ${style.titleH3_5Total} ${style.titleH3_5Subtotal}`}
                      >
                        <div className={`${style.gbCoursePriceTitle}`}>
                          小計
                        </div>
                        <span className={style.gbTotalOuter1}></span>
                        <span className={style.gbTotalOuter2}>
                          <span
                            className={`${style.gbTotal} ${style.gbProductDiscountPrice}`}
                          >
                            0
                          </span>
                          元
                        </span>
                      </Title>
                    )}
                    {!!checkoutMembershipPriceInfo?.title ? (
                      <Title
                        level={3}
                        className={`${style.titleH3_5Tool} ${style.titleH3_5Total} ${style.titleH3_5Subtotal}`}
                      >
                        <div className={`${style.gbCoursePriceTitle}`}>
                          {checkoutMembershipPriceInfo?.title}
                        </div>
                        <span className={style.gbTotalOuter1}>
                          <span className={style.gbTotal}>
                            {checkoutMembershipPriceInfo?.discount / 10}
                          </span>
                          折
                        </span>
                        <span className={style.gbTotalOuter2}>
                          <span
                            className={`${style.gbTotal} ${style.gbProductDiscountPrice}`}
                          >
                            -{checkoutMembershipPriceInfo?.discountPrice}
                          </span>
                          元
                        </span>
                      </Title>
                    ) : (
                      <></>
                    )}
                    {!!checkoutPriceInfo?.shippingFee ? (
                      <Title
                        level={3}
                        className={`${style.titleH3_5Tool} ${style.titleH3_5Total} ${style.titleH3_5Subtotal}`}
                      >
                        <div className={`${style.gbCoursePriceTitle}`}>
                          運費
                        </div>
                        <span className={style.gbTotalOuter1}></span>
                        <span className={style.gbTotalOuter2}>
                          <span
                            className={`${style.gbTotal} ${style.gbProductDiscountPrice}`}
                          >
                            {checkoutPriceInfo?.shippingFee}
                          </span>
                          元
                        </span>
                      </Title>
                    ) : (
                      <Title
                        level={3}
                        className={`${style.titleH3_5Tool} ${style.titleH3_5Total} ${style.titleH3_5Subtotal}`}
                      >
                        <div className={`${style.gbCoursePriceTitle}`}>
                          運費
                        </div>
                        <span className={style.gbTotalOuter1}></span>
                        <span className={style.gbTotalOuter2}>
                          <span
                            className={`${style.gbTotal} ${style.gbProductDiscountPrice}`}
                          >
                            0
                          </span>
                          元
                        </span>
                      </Title>
                    )}
                    {!!checkoutPriceInfo?.total ? (
                      <Title
                        level={3}
                        className={`${style.titleH3_5Tool} ${style.titleH3_5Total} ${style.titleH3_5Subtotal}`}
                      >
                        <div className={`${style.gbCoursePriceTitle}`}>
                          總計
                        </div>
                        <span className={style.gbTotalOuter1}></span>
                        <span className={style.gbTotalOuter2}>
                          <span
                            className={`${style.gbTotal} ${style.gbProductDiscountPrice}`}
                          >
                            {checkoutPriceInfo?.total}
                          </span>
                          元
                        </span>
                      </Title>
                    ) : (
                      <Title
                        level={3}
                        className={`${style.titleH3_5Tool} ${style.titleH3_5Total} ${style.titleH3_5Subtotal}`}
                      >
                        <div className={`${style.gbCoursePriceTitle}`}>
                          總計
                        </div>
                        <span className={style.gbTotalOuter1}></span>
                        <span className={style.gbTotalOuter2}>
                          <span
                            className={`${style.gbTotal} ${style.gbProductDiscountPrice}`}
                          >
                            0
                          </span>
                          元
                        </span>
                      </Title>
                    )}
                  </div>
                </Col>
              </Content>
            </Col>
          </Col>
        </Row>
        <Row className={`${style.gbRowAntButton}`}>
          {disableBtn ? (
            <AntButton
              className={`${style.gbAntButton} ${style.gbAntButtonConfirm}`}
              onClick={async (e) => {
                await submitForm(e);
              }}
              disabled
            >
              確認
              {showAntLoading ? <LoadingOutlined /> : <></>}
            </AntButton>
          ) : (
            <AntButton
              className={`${style.gbAntButton} ${style.gbAntButtonConfirm}`}
              onClick={async (e) => {
                e?.preventDefault();
                await submitForm(e);
              }}
            >
              確認
              {showAntLoading ? <LoadingOutlined /> : <></>}
            </AntButton>
          )}
          <Row className={style.gbBottomRow1}>
            <Title
              level={4}
              className={style.titleH4_7}
              onClick={(e) => {
                e?.preventDefault();
                if (
                  window.confirm(
                    "確定要返回揪團頁面嗎？\n( 表單內容可能會被清空 )"
                  )
                ) {
                  window.localStorage.removeItem("teamId");
                  window.localStorage.removeItem("pIds");
                  clearUrlQuery();
                  setGbPage("CreateGroupPage");
                }
              }}
            >
              返回揪團頁面
            </Title>
          </Row>
          <Row className={style.gbBottomRow3}>
            <div className={style.gbErrMsg}>
              {billingFormErrMsg ? `帳單資訊錯誤：${billingFormErrMsg}` : ``}
            </div>
          </Row>
          <Row className={style.gbBottomRow3}>
            <div className={style.gbErrMsg}>
              {shippingFormErrMsg ? `寄送資訊錯誤：${shippingFormErrMsg}` : ``}
            </div>
          </Row>
          <Row className={style.gbBottomRow3}>
            <div className={style.gbErrMsg}>{errMsg}</div>
          </Row>
        </Row>
        {/* <Row className={style.gbBottomRow2}>
            <Title level={4} className={style.titleH4}>KidPro團隊保留活動最終解釋權</Title>
          </Row> */}
        <div style={{ padding: "24px" }}></div>
      </Container>
      {/* </Form>
      ) : gbPage === "CreateGroupPage" && !! loginToken ? (
         <GroupBuyCreateGroup gbPage={gbPage} setGbPage={setGbPage}></GroupBuyCreateGroup>
      ) : gbPage === "LandingPage" && ! loginToken ? (
         <GroupBuy gbPage={gbPage} setGbPage={setGbPage}></GroupBuy>
      ) 
      // : gbPage === "TapPayPage" && !! loginToken ? (
      //   <TapPay gbPage={gbPage} setGbPage={setGbPage}></TapPay>
      // ) 
      : (
         <></>
      )} */}
      <Popup NOuaabindLine={NOuaabindLine} LoginToken={LoginToken}></Popup>
    </>
  );
}

export default GroupBuyCheckoutAddTeam;
